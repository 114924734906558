<template>
    <div class="container">
        <div v-for="(i, index) in JSON.parse(details)" :key="index">
            <h4 class="ml-lg-6 ml-4 mb-3" v-if="i.type==='h'">{{ i.value }}</h4>
            <div v-if="i.type==='d'" class="para ml-lg-6 ml-4 mb-3 font-manrope-regular bb-black">
                <p>{{ i.value }}</p>
            </div>
            <div v-if="i.type==='b'" class="ul ml-lg-7 ml-4  mb-3 font-manrope-regular  bb-black">
                <ul>
                    <li v-for="(j, ind) in i.value" :key="ind">{{ j.value }}</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesPage2',
    props: {
        details: {
            type: String, default: null
        }
    }
};
</script>

<style scoped>
p {
    color: #323232;
}

li {
    color: #323232;
    line-height: 2rem;
}
</style>
