<template>
    <div>
        <div class="fl-x fl-j-c font-manrope-semi-bold mb-5">
            <div class="row mt-6" v-if="details === null">
                <div class="col-12 col-lg-4 col-md-6">
                    <div class="px-lg-0 px-3">
                        <div class="fl-x fl-a-s ">
                            <h5 class="ml-5 mb-4 text-danger fs-lg-3">COOPERATIVE OFFICE</h5>
                        </div>
                        <div class="fl-x fl-a-s cursor-pointer" @click="openMap('13.033995689785085', '80.23570321239274')">
                            <img src="../../assets/img/Images/icons/location.svg" alt="" class="mr-3  ">
                            <div>
                                <p class="para mb-4 text-justify">CyberFort DigiSec Solution Private Ltd FlatB NuTech Pavithra First
                                    Floor No. 6/24 Srinivasa Pillai Street West Mambalam, Chennai 600 033. Tamilnadu,
                                    India</p>
                            </div>
                        </div>
                        <div class="fl-x fl-a-s ">
                            <img src="../../assets/img/Images/icons/phone.svg" alt="" class="mr-3 ">
                            <div class="para">
                                <p>+91 9345242900</p>
                                <p class="mb-4">4435003271</p>
                            </div>
                        </div>
                        <div class="fl-x fl-a-s ">
                            <img src="../../assets/img/Images/icons/Email.svg" alt="" class="mr-3 ">
                            <p class="mt-1">info@cyberfort.in</p>
                        </div>
                    </div>
                </div>
                <!--                ------------------------------------------------------------------------------->
                <div class="col-12 col-lg-4 col-md-6 pl-lg-6">
                    <div class="px-lg-0 px-3 pt-md-5">
                        <div class="fl-x">
                            <h5 class="heading  mb-4 text-danger fs-lg-3">BRANCH OFFICE</h5>
                        </div>
                        <div class="fl-x fl-a-s cursor-pointer text-justify" @click="openMap('13.033995689785085', '80.23570321239274')">
                            <img src="../../assets/img/Images/icons/location.svg" alt="" class="mr-3 ">
                            <div>
                                <p class="mb-5">18/50, Moosa Street,<br> T Nagar, Chennai 600 014, Tamilnadu, India</p>
                            </div>
                        </div>
                        <div class="fl-x fl-a-s ">
                            <img src="../../assets/img/Images/icons/phone.svg" alt="" class="mr-3 ">
                            <div class="para">
                                <p>+91 9345242900</p>
                                <p class="mb-4">4435003271</p>
                            </div>
                        </div>
                        <div class="fl-x fl-a-s">
                            <img src="../../assets/img/Images/icons/Email.svg" alt="" class="mr-3 ">
                            <p>info@cyberfort.in</p>
                        </div>
                    </div>
                </div>
                <!--               ----------------------------------------------------------------------------------->
                <div class="col-12 col-lg-4 col-md-6 mt-md-5 mt-lg-0">
                    <div class="px-lg-0 px-3">
                        <div class="fl-x fl-a-s">
                            <h5 class=" heading  mb-4 text-danger fs-lg-3">BRANCH OFFICE</h5>
                        </div>
                        <div class="fl-x fl-a-s cursor-pointer" @click="openMap('13.033995689785085', '80.23570321239274')">
                            <img src="../../assets/img/Images/icons/location.svg" alt="" class="mr-3 ">
                            <div>
                                <p class="pr-8 mb-5">18/50, Moosa Street,<br> T Nagar, Chennai 600 014, Tamilnadu, India
                                </p>
                            </div>
                        </div>
                        <div class="fl-x fl-a-s">
                            <img src="../../assets/img/Images/icons/phone.svg" alt="" class="mr-3 ">
                            <div class="para">
                                <p>+91 9345242900</p>
                                <p class="mb-4">+91 4435003271</p>
                            </div>
                        </div>
                        <div class="fl-x fl-a-s">
                            <img src="../../assets/img/Images/icons/Email.svg" alt="" class="mr-3 ">
                            <p>info@cyberfort.in</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="row mt-5" v-else>
                <div class="col-12 col-lg-4 col-md-6 pb-5" :class="{'mt-lg-4': i>2}" v-for="(item,i) in details"
                     :key="i">
                    <div class="px-lg-0 px-3">
                        <div class="fl-x fl-a-s ">
                            <h5 class="ml-5 mb-4 text-danger fs-lg-3">{{ item.place }}</h5>
                        </div>
                        <div class="fl-x fl-a-s cursor-pointer" @click="openMap(item.latitude, item.longitude)">
                            <img src="../../assets/img/Images/icons/location.svg" alt="" class="mr-3  ">
                            <div>
                                <p class="para mb-4 text-justify">{{
                                        item.address
                                    }}</p>
                            </div>
                        </div>
                        <div class="fl-x fl-a-s ">
                            <img src="../../assets/img/Images/icons/phone.svg" alt="" class="mr-3 ">
                            <div class="para">
                                <p>{{ item.phone_number }}</p>
                                <p class="mb-4">{{ item.alternative_phone_number }}</p>
                            </div>
                        </div>
                        <div class="fl-x fl-a-s ">
                            <img src="../../assets/img/Images/icons/Email.svg" alt="" class="mr-3 ">
                            <p class="">{{ item.email }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'detailspage',
    props: {
        details: {
            type: Array,
            default: null
        }
    },
    methods: {
        openMap (lat, lon) {
            if (!lat || !lon) {
                lat = '13.033995689785085';
                lon = '80.23570321239274';
            }
            window.open('https://www.google.com/maps/place/' + lat + ',' + lon);
        }
    }
};
</script>

<style scoped>
.fl-x h5 {
    color: #cb1313;
}

.para {
    font-size: 15px;
    /*width: 348px;*/
}

p {
    font-size: 15px;
}

.heading {
    padding-left: 2.2rem;
}

/*@media (min-width: 768px) and (max-width: 1024px) {*/

/*}*/

</style>
