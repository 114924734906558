<template>
    <div class="py-5">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 ">
                    <section-heading size="xlg"
                                     title="CERTIFICATION COURSE TRAINING" class="mb-5  px-lg-5  "
                                     info="Impart industry needed Cybersecurity Professional certification courses & Training
                                     to interested people as per their professional needs for their professional Career Growth."></section-heading>
                    <hr style="width:50%">

                    <div class="text-center text-primary py-7" v-if="loading">
                        <loading-animation class="fs-10"/>
                        <p>Please wait while data is being loaded...</p>
                    </div>
                    <div v-if="training && !loading">
                        <p class="font-manrope-bold mt-lg-5 mt-5 mb-5 text-primary  fs-lg-4 fs-5">
                            {{ training.title }}
                        </p>
                        <div class="train1 img-fluid ">
                            <img v-if="!training.image" alt="training-image" src="../assets/img/Images/training1.png">
                            <img v-else alt="training-image" :src="training.image">
                        </div>
                        <!--                        <div v-if="!training.image" class="graphic mt-5 mb-3 bg-cover top-img" style=""-->
                        <!--                             :style="backgroundImage(topImgPlaceholder)">-->
                        <!--                            &lt;!&ndash;                        <img :src="details.image" class="img-fluid">&ndash;&gt;-->
                        <!--                        </div>-->
                        <!--                        <div v-else class="graphic mt-5 mb-3 bg-cover top-img" style=""-->
                        <!--                             :style="backgroundImage(training.image)">-->
                        <!--                            &lt;!&ndash;                        <img :src="details.image" class="img-fluid">&ndash;&gt;-->
                        <!--                        </div>-->
                        <p v-if="training.description_1 && training.description_1!=='undefined'"
                           class="font-manrope-regualr text-justify fl-j-c txt-p  mt-lg-5 mt-5 mb-5">
                            {{ training.description_1 }}
                        </p>
                        <p v-if="training.description_2 && training.description_2!=='undefined'"
                           class="font-manrope-regular text-justify fl-j-c txt-p   mt-lg-5 mt-5 mb-5">
                            {{ training.description_2 }}
                        </p>
                        <p v-if="training.description_3 && training.description_3!=='undefined'"
                           class="font-manrope-regular text-justify fl-j-c txt-p   mt-lg-5 mt-5 mb-5">
                            {{ training.description_3 }}
                        </p>
                        <div class="mt-3">
                            <course-details :training="training"></course-details>
                        </div>
                        <div class="mt-5" v-if="training.schedule.length !== 0">
                            <div class="ml-3">
                                <section-heading title="Schedule" align="left" bottom-tag-align="left"
                                                 class="font-manrope-semi-bold mb-5"></section-heading>
                                <schedule-page :training="training"></schedule-page>
                            </div>
                        </div>
                        <p v-else class="py-7 text-center">
                            No any schedules currently available for {{ training.title }}.
                        </p>

                    </div>
                </div>

            </div>
        </div>
        <strengthen-data-security-tag class="lg mt-5"></strengthen-data-security-tag>
        <div class="container mt-md-5">
            <div class="pr">
                <EnquiryFormPage :training="training_id"></EnquiryFormPage>
            </div>
        </div>
    </div>
</template>

<script>
import CourseDetails from '../components/Training/CourseDetails';
import SchedulePage from '../components/Training/SchedulePage';
import EnquiryFormPage from '../components/Training/EnquiryFormPage';
import axios from 'secure-axios';
import urls from '../data/urls';
import topImgPlaceholder from '../assets/img/Images/training1.png';
// import TrainingModal from '@components/Modals/TrainingModal';
import StrengthenDataSecurityTag from '../components/Solutions/second';

export default {
    name: 'TrainingPage',
    components: { EnquiryFormPage, SchedulePage, CourseDetails, StrengthenDataSecurityTag },
    data () {
        return {
            loading: false,
            training: null,
            training_name: this.$route.params.training,
            training_id: this.$route.params.id,
            scheduleOptions: [],
            countriesOptions: [],
            model: {
                event_training_schedule: '',
                country: '',
                city: '',
                name: '',
                phone_number: '',
                email: '',
                captcha_URL: '',
                captcha_key: '',
                captcha: ''
            },
            captchaError: '',
            regID: '',
            payModel: {
                amount: '',
                firstname: '',
                name: '',
                email: '',
                phone: '',
                training_name: '',
                action: '',
                address1: '',
                address2: '',
                city: '',
                country: '',
                furl: '',
                hash_string: '',
                hash: '',
                key: '',
                lastname: '',
                merchant_key: '',
                productinfo: '',
                service_provider: '',
                state: '',
                surl: '',
                txnid: '',
                udf1: '',
                udf2: '',
                udf3: '',
                udf4: '',
                udf5: '',
                zipcode: ''
            },
            topImgPlaceholder: topImgPlaceholder
        };
    },

    computed: {
        name () {
            return this.$route.params.training;
        }
    },
    watch: {
        $route () {
            this.loadData();
        }
    },

    mounted () {
        this.loadData();
    },

    methods: {
        clicked () {
            this.$refs.register.show();
        },

        onSubmit () {
            const that = this;
            that.loading = true;
            const formValidator = that.$refs.formValidator;
            axios.form(urls.registration, that.model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.regID = json.reg_id;
                    that.loading = false;
                    that.$refs.register.close();
                    that.$refs.paymentContinue.show();
                } else {
                    if (json.captcha === false) {
                        that.captchaError = 'Invalid Captcha';
                        that.model.captcha_URL = json.image_url;
                        that.model.captcha_key = json.key;
                        that.loading = false;
                    } else {
                        that.loading = false;
                        formValidator.setErrors(json.errors);
                        that.message = json.errors.__all__ || '';
                        that.formError = json.errors;
                    }
                }
            }).catch(function (err) {
                that.loading = false;
                console.log('error', err);
            });
        },

        registerStatus () {
            const that = this;
            that.loading = true;
            axios.form(urls.paymentInitials, { reg_id: that.regID }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.setModel(json.payu);
                    that.loading = false;
                    that.$refs.paymentContinue.close();
                    that.$refs.paymentConfirm.show();
                } else {
                    that.loading = false;
                }
            }).catch(function (err) {
                console.log('error : ', err);
            });
        },

        async loadData () {
            const that = this;
            that.loading = true;
            const id = that.$route.params.id;
            axios.form(urls.training.details, { id: id }).then(function (response) {
                const json = response.data;
                that.training = json.data;
                that.scheduleOptions = json.schedule_options.data;
                that.countriesOptions = json.countries.data;
                that.model.captcha_URL = json.image_url;
                that.model.captcha_key = json.key;
                that.loading = false;
            }).catch(function (err) {
                console.log('error : ', err);
            });
        },

        setModel (data) {
            this.payModel.action = data.action;
            this.payModel.name = data.name;
            this.payModel.training_name = data.training_name;
            this.payModel.date = data.date;
            this.payModel.address1 = data.address1;
            this.payModel.address2 = data.address2;
            this.payModel.amount = data.amount;
            this.payModel.city = data.city;
            this.payModel.country = data.country;
            this.payModel.email = data.email;
            this.payModel.firstname = data.firstname;
            this.payModel.furl = data.furl;
            this.payModel.hash_string = data.hash_string;
            this.payModel.hash = data.hashh;
            this.payModel.key = data.key;
            this.payModel.lastname = data.lastname;
            this.payModel.merchant_key = data.merchant_key;
            this.payModel.phone = data.phone;
            this.payModel.productinfo = data.productinfo;
            this.payModel.service_provider = data.service_provider;
            this.payModel.state = data.state;
            this.payModel.surl = data.surl;
            this.payModel.txnid = data.txnid;
            this.payModel.udf1 = data.udf1;
            this.payModel.udf2 = data.udf2;
            this.payModel.udf3 = data.udf3;
            this.payModel.udf4 = data.udf4;
            this.payModel.udf5 = data.udf5;
            this.payModel.zipcode = data.zipcode;
        },

        payToRedirect () {
            this.$refs.paymentConfirm.close();
            this.$refs.paymentRedirect.show();
        },
        backgroundImage (image) {
            return { backgroundImage: 'url(' + image + ')' };
        }
    }
};
</script>

<style scoped lang="scss">
.text-grey {
    color: darkgrey;
}

hr {
    border-bottom: 1px solid black;
    display: block;
    margin-top: 0.5em;
    margin-bottom: 0.5em;
    margin-left: 25%;
    margin-right: auto;
    border-style: inset;
    border-width: 1px;
}

.train img {
    width: 100% !important;
    //height: 500px !important;
}

.txt-p {
    color: #323232;
}

.train1 img {
    width: 100% !important;
    height: 430px !important;
}

.pr {
    position: relative;
    top: -136px !important;
}

.section-head {
    position: relative;
    top: -179px !important;
}

@media(max-width: 768px) {
    .pr {
        position: relative;
        top: 0px !important;
    }
    .section-head {
        position: relative;
        top: -71px !important;
    }
    .train1 img {
        width: 100% !important;
        height: 250px !important;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .section-head {
        position: relative;
        top: -113px !important;
    }
    .pr {
        position: relative;
        top: 0px !important;
    }
}

.top-img {
    width: 100% !important;
    height: 15rem;
    @media (min-width: 992px) {
        width: 100% !important;
        height: 28rem;
    }
}
</style>
