import { render, staticRenderFns } from "./BlogArticlePage.vue?vue&type=template&id=28c3a58e&scoped=true&"
import script from "./BlogArticlePage.vue?vue&type=script&lang=js&"
export * from "./BlogArticlePage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28c3a58e",
  null
  
)

export default component.exports