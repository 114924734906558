<template>
    <transition :enter-active-class="enterTransitionClass" :leave-active-class="exitTransitionClass" mode="out-in" appear>
        <slot></slot>
    </transition>
</template>
<script>
import TransitionAnimationMixin from './TransitionAnimationMixin';

export default {
    name: 'AnimateTransition',
    extends: TransitionAnimationMixin
};
</script>
