<template>
    <div class="w-100 mb-3 pb-3 ">
        <header-tag color=""  class="">
            <template #header>
                <div class="fl-y ">
                    <div class="d-block text-primary fs-lg-2 fs-1 pb-2 pt-lg-3 font-manrope-semi-bold" style="display: block;">{{ heading }}</div>
                    <div style="display: block;" class="text-2 text-justify fs-lg-1  fs--2 pb-2 font-manrope-medium">{{ description }}</div>
                </div>

            </template>
            <template #buttons>
                <transition name="fade-accordion" mode="out-in">
                    <btn size="sm" shadow="0" icon="fa fa fa-chevron-down text-primary cursor-ponter" class="border-rounded"
                         color=""
                         key="open-btn" @click="toggleSection" v-if="!sectionVisibility"></btn>
                    <btn size="sm" shadow="0" icon="fa fa fa-chevron-up cursor-ponter text-primary" class="border-rounded"
                         color=""
                         key="close-btn" @click="toggleSection" v-else></btn>
                </transition>
            </template>
        </header-tag>
        <transition name="fade">
            <section class="mt-3" v-if="sectionVisibility">
                <slot></slot>
            </section>
        </transition>
    </div>
</template>
<script> import HeaderTag from './HeaderTag';

export default {
    name: 'CustomAccordion',
    components: { HeaderTag },
    data () {
        return { sectionVisibility: false };
    },
    props: {
        heading: { type: String },
        description: {
            type: String,
            default: ''
        }
    },
    methods: {
        toggleSection () {
            this.sectionVisibility = !this.sectionVisibility;
        }
    }
}; </script>
<style scoped>
.bb-black {
    border-bottom: 1px solid slategray;
}
.fade-accordion-enter-active, .fade-leave-active {
    transition: opacity .3s;
}

.fade-accordion-enter, .fade-accordion-leave-to /* .fade-leave-active below version 2.1.8 */
{
    opacity: 0;
}
</style>
