import { render, staticRenderFns } from "./PhotoGalleryPage.vue?vue&type=template&id=5182d75a&scoped=true&"
import script from "./PhotoGalleryPage.vue?vue&type=script&lang=js&"
export * from "./PhotoGalleryPage.vue?vue&type=script&lang=js&"
import style0 from "./PhotoGalleryPage.vue?vue&type=style&index=0&id=5182d75a&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5182d75a",
  null
  
)

export default component.exports