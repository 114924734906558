<template>
    <div>
        <swiper class="swiper swiper-basic pb-3 pb-lg-0" data-mousewheel="1" data-parallax="1" :options="swiperOption">
            <template v-if="data === null">
                <swiper-slide v-for="(img,i) in images" :key="i">
                    <div class="img-contain cursor-pointer" :style="setBgImage(img)"
                         @click="openInnerPage(data.id)"></div>
                    <h4 class="text-primary mt-2 fs-lg-1 font-manrope-extra-bold">Event title</h4>
                </swiper-slide>
            </template>
            <template v-else>
                <template v-if="data.gallery_images.length!==0">
                    <swiper-slide v-for="(img,i) in data.gallery_images" :key="i">
                        <div class="img-contain cursor-pointer" :style="setBgImage(img.image)"
                             @click="openInnerPage(data.id, data.event_title)"></div>
                    </swiper-slide>
                </template>
                <template v-else>
                    <swiper-slide>
                        <div class="img-contain cursor-pointer default-image"
                             @click="openInnerPage(data.id, data.event_title)"></div>
                    </swiper-slide>
                </template>
            </template>

            <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>
            <div class="swiper-button-prev swiper-button-white" slot="button-prev"></div>
            <div class="swiper-button-next swiper-button-white" slot="button-next"></div>
        </swiper>
        <div>
            <h4 class="text-primary mt-2 fs-lg-1 font-manrope-extra-bold">{{ data.event_title }}</h4>
        </div>
    </div>
</template>
<script> import 'swiper/css/swiper.css';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import img1 from '../../assets/img/Images/people.png';
import img2 from '../../assets/img/Images/christmas.png';
import img3 from '../../assets/img/Images/im1.png';

export default {
    name: 'PhotoGalleryCategoryItem',
    components: { Swiper, SwiperSlide },
    props: {
        data: {
            type: Object,
            default: null
        }
    },
    data () {
        return {
            bannerImg: img1,
            bannerMobileImg: img1,
            bannerImg2: img2,
            bannerMobileImg2: img2,
            bannerImg3: img3,
            bannerMobileImg3: img3,
            images: [img1, img2, img3],
            swiperOption: {
                autoHeight: true,
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: { el: '.swiper-pagination', clickable: true },
                mousewheel: false,
                parallax: false,
                centeredSlides: true,
                // autoplay: true,
                autoplay: { delay: 6000, disableOnInteraction: false },
                navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
                breakpoints: {
                    1200: { speed: 1500 },
                    1024: { slidesPerView: 1, spaceBetween: 0, speed: 1000 },
                    768: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    640: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    320: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    // navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                    // }
                }

            }
        };
    },
    methods: {
        setBgImage (image) {
            if (image) {
                return { backgroundImage: 'url(' + image + ')' };
            }
        },
        openInnerPage (item, title) {
            this.$router.push('/gallery-inner-page/' + title + '/' + item);
        }
    }
}; </script>
}
}
<style scoped lang="scss"> .item {
    width: 100%;
    display: flex;
    align-items: center;
}

.img-contain {
    position: relative;
    height: 22rem !important;
    background-position: center;
    border-radius: var(--round-2);
    background-repeat: no-repeat;
    background-size: cover;
}

.swiper-button-prev, .swiper-button-next {
    width: 1.6rem;
    height: 1.6rem;
    border-radius: 50%;
    background-color: white;

    &.swiper-button-disabled {
        display: none;
    }

}

.swiper-button-prev:after, .swiper-button-next:after {
    font-size: 1rem !important;
    color: var(--color-primary) !important;
    font-weight: bold;
}

.default-image {
    background-image: url('../../assets/img/Images/default-event-image.png');
}

/*.bg-home-banner2 {*/
/*    background-image: url('../../assets/img/social-banner/home-banners-mob2.jpg');*/
/*}*/

/*.bg-home-banner3 {*/
/*    background-image: url('../../assets/img/social-banner/home-banners-mob3.jpg');*/
/*}*/

.home-banner-icon {
    display: inline-block;
}

.home-banner-icon img {
    width: 1.2rem;
    margin-left: 20px;
}

.home-slider h1 {
    line-height: 40px;
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    //background-color: rgba(0, 0, 0, 0.4);
    //width: 4rem;
    bottom: 2.5rem;

    .swiper-pagination-bullet {
        border: 1px solid var(--color-white) !important;

    }

}

.swiper-pagination-bullet {
    border: 1px solid var(--color-white) !important;

}

/*.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {*/
/*    bottom: 10px;*/
/*    left: 50%;*/
/*    transform: translate(-50%, 0);*/
/*    width: unset;*/
/*    padding: 3px 25px;*/
/*    background: #2929293b;*/
/*    border-radius: 15px;*/
/*}*/

/*@media (min-width: 992px) {*/
/*    .bg-home-banner {*/
/*        background-image: url('../../assets/img/social-banner/home-banners.jpg');*/
/*    }*/

/*    .bg-home-banner2 {*/
/*        background-image: url('../../assets/img/social-banner/home-banners2.jpg');*/
/*    }*/

/*    .bg-home-banner3 {*/
/*        background-image: url('../../assets/img/social-banner/home-banners3.jpg');*/
/*    }*/

/*    .home-banner-icon img {*/
/*        width: 2rem;*/
/*        margin-left: 20px;*/
/*    }*/
/*}*/
</style>
