<template>
    <div>
        <div class="container py-5">
            <div class="row">
                <div class="col-12">
                    <section-heading size="xlg" title="PHOTO  GALLERY"
                                     info="Visuals of our Vibrant Team’s success story of events. We believe in our Teamwork."></section-heading>
                </div>
                <template v-if="details.length !== 0">
                    <div class="col-12 col-lg-4 col-md-6 mt-6 trans-1" v-for="(item,i) in listingItems" :key="i">
                        <div class="p-3 p-md-0">
                            <photo-gallery-category-item :data="item"></photo-gallery-category-item>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="col-12 col-lg-12 col-md-12 mt-6">
                        <div class="p-3 p-md-0 fl-x-cc">
                            <loading-animation></loading-animation>
                            <p class="mt-3">Loading Data Please wait...</p>
                        </div>
                    </div>
                </template>

                <div class="col-12 mt-7 text-center">
                    <btn text="View All" size="lg" border-radius="0" v-if="viewAllBtn"
                         class="px-5 fs-lg-1 font-poppins-regular" @click="viewAll"></btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import PhotoGalleryCategoryItem from '../../components/gallery/PhotoGalleryCategoryItem';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'PhotoGalleryPage',
    components: { PhotoGalleryCategoryItem },
    data () {
        return {
            dataLoading: false,
            details: [],
            listingItems: null,
            viewAllBtn: true
        };
    },
    mounted () {
        this.loadData();
    },
    methods: {
        async loadData () {
            this.dataLoading = true;
            const response = await axios.get(urls.gallery.list);
            const json = response.data;
            console.log('career-response', response);
            if (json.error === false) {
                this.details = json.data;
                this.listingItems = json.data.slice(0, 6);
                console.log('gallery-details', this.details);
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
        },
        viewAll () {
            this.viewAllBtn = false;
            this.listingItems = this.details;
        }
    }
};
</script>

<style scoped>
.trans-1 {
    transition: all ease-in-out 0.5s;
}
</style>
