<template>
    <div class="py-5">
        <div class="container">
            <section-heading size="xlg" title="SOLUTION & CONSULTANCY SERVICE" info="In order to
                support the organizations in their Digital Transformation journey and adoption of emerging technologies
                like AI,ML,IOT,BlockChain,BigData ina secure manner,we are providing solution and consultancy
                services ina a cost effective with a high value to the organizations: "></section-heading>
<!--            <p class="para font-manrope-regular fs-lg-1 fs&#45;&#45;1 fl-x-cc text-center bb-black mt-5">In order to-->
<!--                support the organizations in their Digital Transformation journey and adoption of emerging <br>technologies-->
<!--                like AI,ML,IOT,BlockChain,BigData ina secure manner,we are providing solution and <br>consultancy-->
<!--                services ina a cost effective with a high value to the organizations: </p>-->
        </div>
    </div>
</template>

<script>
export default {
    name: 'first',
    components: {}
};
</script>

<style scoped>
.head1 {
    text-align: center;
    color: midnightblue;
}

hr.new1 {
    border-top: 1px solid darkblue;
    width: 95px;
    margin-left: 505px;
}

.para {
    text-align: center;
    color: #323232;
}

p {
    color: grey;
}
</style>
