<template>
    <div class="">
        <div class="container pt-4">
            <section-heading size="lg" title="SOLUTION & CONSULTANCY SERVICE"></section-heading>
            <div v-for="(data,i) in details" :key="i">
                <p v-if="details === null"
                   class="para font-manrope-medium fs-lg-1 fs--1 fl-x-cc text-center pb-5 bb-black py-4">In order to
                    support the organizations in their Digital Transformation journey and adoption of emergency <br>technologies
                    like AI,ML,IOT,BlockChain,BigData ina secure manner,we are providing solution and <br>consultancy
                    services ina a cost effective with a high value to the organizations: </p>
                <!--                <p v-else class="para font-manrope-medium fs-lg-1 fs&#45;&#45;1 fl-x-cc text-center pb-5 bb-black py-4">-->
                <!--                    {{ data.description }}-->
                <!--                </p>-->
                <div class="row mt-7 pt-lg-7 pt-md-6">
                    <div class="col-md-6 pl-lg-6 pl-lg-4  mt-lg-n5 mt-n4">
                        <img v-if="details === null" src="../../../assets/img/Images/business-people-meeting.jpg"
                             class="img-fluid">
                        <img v-else :src="data.image" class="img-fluid">
                    </div>
                    <div class="col-md-6 mt-2 mt-lg-n5">
<!--                        <div class="fl-y fl-j-sb h-100p py-3">-->
                            <p v-if="details === null"
                               class="para font-poppins-regular fs-lg-1 fl-x-cc text-center pb-5 bb-black py-4">In
                                order to
                                support the organizations in their Digital Transformation journey and adoption of
                                emergency
                                <br>technologies
                                like AI,ML,IOT,BlockChain,BigData ina secure manner,we are providing solution and <br>consultancy
                                services ina a cost effective with a high value to the organizations: </p>
                            <p v-else
                               class="para font-poppins-regular fs-lg-1 text-justify bb-black pt-2">
                                {{ data.description }}
                            </p>
                            <btn @click="$router.push('/solutions/')" text="Learn More"
                                 border-radius="0" class="px-4"></btn>
<!--                        </div>-->

                        <!--                        <ul v-if="details === null" class="font-manrope-medium pb-5 bb-black">-->
                        <!--                            <li>Digital Transformation - security & controls design considerations & implementation <br>assistance-->
                        <!--                            </li>-->
                        <!--                            <li>Assit in Data Protection impact Assessment(DPIA) and EU GDPR <br>implementation</li>-->
                        <!--                            <li>Blockchain initiative - Security & controls assurance <br>service</li>-->
                        <!--                            <li>Assist in 3rd party Cyber Security products & services selection <br>& implementation-->
                        <!--                            </li>-->
                        <!--                        </ul>-->
                        <!--                        <template v-else>-->
                        <!--                            <div v-for="(i, index) in JSON.parse(data.solution_items)" :key="index">-->
                        <!--                                <h4 class="mb-3" v-if="i.type==='h'">{{ i.value }}</h4>-->
                        <!--                                <div v-if="i.type==='d'"-->
                        <!--                                     class="para mb-3 font-manrope-medium  bb-black fs-lg-1">-->
                        <!--                                    <p>{{ i.value }}</p>-->
                        <!--                                </div>-->
                        <!--                                <div v-if="i.type==='b'"-->
                        <!--                                     class="ul mb-3 font-manrope-medium  bb-black fs-lg-1">-->
                        <!--                                    <ul>-->
                        <!--                                        <li v-for="(j, ind) in i.value" :key="ind">{{ j.value }}</li>-->
                        <!--                                    </ul>-->
                        <!--                                </div>-->
                        <!--                            </div>-->
                        <!--                        </template>-->
                    </div>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'SolutionsAndConsultancy',
    props: {
        details: {
            type: Array, default: null
        }
    }
};
</script>

<style scoped lang="scss">
img {
    width: 95%;
}

h4 {
    color: #676767;
}

li {
    //line-height: 2rem;
    color: #676767;
}

@media (max-width: 765px) {
    img {
        width: 107%;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    img {
        width: 102%;
    }
}
</style>
