<template>
    <div>
        <div class="container py-5">
            <div class="row">
                <div class="col-12">
                    <section-heading size="lg" title="BLOG"
                                     info="A platform to share the information happening at cybersecurity industry provided by our technical team."></section-heading>
                </div>
                <div>
                    <h3 class="text-primary fs-lg-4 font-manrope-medium mb-4 ml-3"> All Blog Posts</h3>
                </div>
                <div class="col-12 text-center text-primary py-7" v-if="dataLoading">
                    <loading-animation class="fs-10"/>
                    <p>Please wait while data is being loaded...</p>
                </div>
                <div class="col-12" v-else>
                    <div class="row">
                        <template v-if="details.length !== 0">
                            <div class="col-lg-4 col-md-6 mt-4 mt-lg-0" v-for="(item,i) in details" :key="i">
                                <recent-blog-post1 :data="item"></recent-blog-post1>
                            </div>
                        </template>
                        <p v-if="details.length === 0 && !dataLoading"
                           class="text-center py-7">
                            No blogs available.
                        </p>
                    </div>
                </div>
                <div v-if="total > perPage" class="col-12">
                    <div class="fl-x fl-j-c">
                        <blog-pagination @refresh="refreshList" :lastPage="lastPage" :total="total" :to="to" :from="from"
                                         :currentPage="currentPage" :perPage="perPage"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RecentBlogPost1 from '../../components/home-components/RecebtBlogPost1';
import BlogPagination from '../../components/blogs/BlogPagination';
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'AllBlogsListing',
    components: { BlogPagination, RecentBlogPost1 },
    data () {
        return {
            currentPage: 1,
            lastPage: 0,
            total: 0,
            from: 0,
            to: 0,
            perPage: 9,
            dataLoading: false,
            details: []
        };
    },
    mounted () {
        this.loadData();
    },
    methods: {
        refreshList (page) {
            this.currentPage = page;
            this.loadData();
        },
        async loadData () {
            this.dataLoading = true;
            const response = await axios.get(urls.blog.blogViewAll, {
                params: {
                    page: this.currentPage,
                    per_page: this.perPage

                }
            });
            const json = response.data;
            if (json.status === true) {
                this.details = json.data;
                this.currentPage = json.current_page;
                this.lastPage = json.last_page;
                this.total = json.total;
                this.from = json.from;
                this.to = json.to;
                this.perPage = json.per_page;
                this.$nextTick(() => {
                    window.scrollTo(0, 0);
                });
                this.dataLoading = false;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        }
    }
};
</script>

<style scoped>

</style>
