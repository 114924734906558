<template>
    <div class="font-manrope-semi-bold mb-5">
        <h3 class="mb-5 text-primary">Enquiry Form </h3>
        <form action="">
            <div class="fl-x">
                <validation-observer slim v-slot="{handleSubmit}" ref="formValidator">
                    <form class="row" role="form" @submit.prevent="handleSubmit(SubmitForm)">
                        <div class="col-12 col-md-6">
                            <validated-input label="First Name:" name="First Name" border-radius="0"
                                             v-model="model.first_name" :disabled="loading"
                                             :rules="rules.first_name" class="c-input-primary"></validated-input>
                        </div>
                        <div class="col-12 col-md-6">
                            <validated-input label="Middle Name:" name="Middle Name" border-radius="0"
                                             v-model="model.midile_name" :disabled="loading"
                                             :rules="rules.midile_name" class="c-input-primary"></validated-input>
                        </div>
                        <div class="col-12 col-md-6">
                            <validated-input label="Last Name:" name="Last Name" border-radius="0" :disabled="loading"
                                             v-model="model.last_name"
                                             :rules="rules.last_name" class="c-input-primary"></validated-input>
                        </div>
                        <div class="col-12 col-md-6">
                            <validated-input label="Contact No:" name="Contact No:" :disabled="loading"
                                             border-radius="0" v-model="model.phone_number" type="number"
                                             :rules="rules.phone_number" class="c-input-primary"></validated-input>
                        </div>
                        <div class="col-12 col-md-6">
                            <validated-input label="Email:" name="E-Mail" type="email" border-radius="0"
                                             v-model="model.email" :disabled="loading"
                                             :rules="rules.email"
                                             class="c-input-primary"></validated-input>
                        </div>
                        <div class="col-12 col-md-6">
                            <validated-input label="Company:" name="Company" border-radius="0" v-model="model.company"
                                             :rules="rules.company" :disabled="loading"
                                             class="c-input-primary"></validated-input>
                        </div>
                        <div class="col-12">
                            <validated-text-area label="Message:" name="Message" v-model="model.message"
                                                 :rules="rules.message" :disabled="loading"
                                                 border-radius="0" class="c-input-primary"
                                                 width="700px"></validated-text-area>
                        </div>
                        <div class="col-12">
                            <validated-checkbox :disabled="loading" v-model="model.recive_newsletters"
                                                :rules="rules.recive_newsletters" label="Please tick if you wish to recieve newsletters regarding news,
                            products, services and offers about Cyberfort through email."></validated-checkbox>
                        </div>

                        <div class="col-12">
                            <div class="fl-x fl-a-c">
                                <validated-checkbox :disabled="loading" v-model="model.privacy"
                                                    class="m-0 p-0 checkbox-p-0"></validated-checkbox>
                                <span @click="showPrivacyPolicy" class="cursor-pointer fs-lg-1">Privacy Policy</span>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mt-4">
                                <mark class="fl-x-cc grey p-1 mb-0">
                                    <div class="row fl-te-c">
                                        <div class="col-10">
                                            <img class="img-fluid" v-if="model.captcha_URL"
                                                 :src="model.captcha_URL + '200/'"
                                                 alt="Captcha"/>
                                        </div>
                                        <div @click="loadCaptcha" class="col-2">
                                            <loading-animation v-if="captchaLoading" class="text-primary"/>
                                            <i v-else class="fa fa-refresh"/>
                                        </div>
                                    </div>
                                </mark>
                            </div>
                            <div class="col-12 col-md-6 p-0 mt-3">
                                <validated-input label="Captcha*" name="Captcha" border-radius="0"
                                                 v-model="model.captcha" :rules="{required: true}" :disabled="loading"
                                                 class="c-input-primary"></validated-input>
                            </div>
                            <div class="col-12 col-md-6 p-0 mb-3 text-3 text-danger" v-if="captchaError">
                                {{ captchaError }}
                            </div>
                            <div class="mt-2">
                                <btn text="Send Message" type="submit" loading-text="Sending..."
                                     border-radius="0" :loading="loading" :disabled="loading || !model.privacy"/>
                            </div>
                        </div>
                    </form>
                </validation-observer>
            </div>
        </form>
    </div>
</template>

<script>
import urls from './../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'formpage',
    data () {
        return {
            model: {
                first_name: '',
                midile_name: '',
                last_name: '',
                email: '',
                phone_number: '',
                company: '',
                message: '',
                recive_newsletters: '',
                privacy: false,
                captcha_URL: '',
                captcha_key: '',
                captcha: ''
            },
            loading: false,
            captchaError: '',
            captchaLoading: false,
            rules: {
                first_name: {
                    required: true
                },
                midile_name: {
                    required: false
                },
                last_name: {
                    required: true
                },
                email: {
                    required: true
                },
                phone_number: {
                    required: true,
                    min: 10,
                    max: 10
                },
                company: {
                    required: true
                },
                message: {
                    required: true
                },
                recive_newsletters: {
                    required: false
                }
            }
        };
    },
    mounted () {
        this.loadCaptcha();
    },
    methods: {
        showPrivacyPolicy () {
            window.open('/privacy-policy/');
        },
        async loadCaptcha () {
            this.captchaLoading = true;
            const response = await axios.get(urls.getCaptcha);
            const json = response.data;
            if (json.error === false) {
                this.model.captcha_key = json.key;
                this.model.captcha_URL = json.image_url;
                this.model.captcha = '';
                setTimeout(() => {
                    this.captchaLoading = false;
                }, 1000);
            } else {
                this.$notify('Could not load captcha, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            setTimeout(() => {
                this.captchaLoading = false;
            }, 1000);
        },
        async SubmitForm () {
            this.loading = true;
            this.captchaFormError('', true);
            const formValidator = this.$refs.formValidator;
            const response = await axios.form(urls.contactUs, this.model);
            const json = response.data;
            if (json.error === false) {
                this.loading = false;
                this.$notify('Submitted Successfully', '', {
                    type: 'success'
                });
                this.$router.go();
            }
            if (json.error === true) {
                formValidator.setErrors(json.errors);
            }
            if (json.captcha === false) {
                this.loading = false;
                this.captchaFormError(json, false);
            }
            this.loading = false;
        },
        captchaFormError (json, clear) {
            if (clear) {
                this.captchaError = '';
            } else {
                this.captchaError = 'Invalid Captcha';
                this.model.captcha_URL = json.image_url;
                this.model.captcha_key = json.key;
            }
        },
        phoneNumberValidate () {
            console.log(/^\d{10}$/.test(this.model.phone_number));
            if (/^\d{10}$/.test(this.model.phone_number)) {
                this.rules.phone_number.validation = true;
            } else {
                this.rules.phone_number.validation = false;
            }
        }
    }
};
</script>

<style scoped>
.container {
    /*background-color: #8F9BB3;*/
    align-content: center;
}

.container textarea {

    width: 100%;
}

.messagebox {
    align-content: center;
}

. fl-x btn {
    color: #4d4dc4;
}
</style>
