<template>
    <swiper class="swiper c-swiper" :options="swiperOption">
        <swiper-slide v-for="(img,i) in details" :key="i">
            <!--      <div class="img-contain bg-cover cursor-pointer d-sm-none" :style="setImage(img.image)"></div>-->
            <!--      <div v-if="img.mobile_image" class="img-contain bg cursor-pointer d-lg-none"-->
            <!--           :style="setImage(img.mobile_image)"></div>-->
            <div class="img-contain cursor-pointer bg-cover" :style="setImage(img.image)"></div>
        </swiper-slide>
        <div class="swiper-pagination" slot="pagination"></div>
    </swiper>
</template>
<script> import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import img1 from '../../assets/web/home/banner-bg.svg';
import img1Mobile from '../../assets/web/home/banner-mobile.svg';
// import img3 from '../../assets/web/home/nils-leonhardt-YeBI31kK2_4-unsplash.jpg';

export default {
    name: 'BannerSlider',
    props: {
        details: {
            type: Array, default: null
        }
    },
    title: 'Fade effect',
    components: { Swiper, SwiperSlide },
    data () {
        return {
            swiperOption: {
                autoHeight: true,
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: { el: '.swiper-pagination', clickable: true },
                mousewheel: false,
                parallax: false,
                centeredSlides: true,
                // autoplay: true,
                autoplay: { delay: 6000, disableOnInteraction: false },
                navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
                breakpoints: {
                    1200: { speed: 1500 },
                    1024: { slidesPerView: 1, spaceBetween: 0, speed: 1000 },
                    768: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    640: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    320: { slidesPerView: 1, spaceBetween: 0, speed: 800 }
                }
            },
            images: [
                {
                    webImg: img1,
                    mobile: img1Mobile
                }
            ]
        };
    },
    methods: {
        setBgImage (image, screen) {
            if (screen === 'web') {
                return { backgroundImage: 'url(' + image.webImg + ')' };
            } else if (screen === 'mobile') {
                return { backgroundImage: 'url(' + image.mobile + ')' };
            }
        },
        setImage (image) {
            return { backgroundImage: 'url(' + image + ')' };
            // return { backgroundImage: 'url(' + img3 + ')' };
        }
    }
}
; </script>
<style lang="scss" scoped>
    .swiper {
        height: 30vh;
        @media (min-width: 768px) and (max-width: 1024px) {
            height: 30vh !important;
        }
        @media (min-width: 992px) {
            /*height: 89vh;*/
            height: 50vh;
        }

        .swiper-slide {
            /*background-position: center;*/
            background-size: contain;
            @media (min-width: 992px) {
                background-size: cover !important;
            }

            &.slide-1 {
                //background-image: url('../../assets/img/banner/banner1.jpg')
            }

            &.slide-2 {
                //background-image: url('../../assets/img/banner/banner2.jpg')
            }

            &.slide-3 {
                //background-image: url('../../assets/img/banner/banner3.jpeg')
            }
        }
    }

    .overlay-dark {
        background: rgba(0, 0, 0, 0.46);
        width: 100vw;
        //height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 100px
    }

    .img-contain {
        position: relative;
        height: 30vh !important;
        @media (min-width: 768px) and (max-width: 1024px) {
            height: 30vh !important;
        }
        @media (min-width: 992px) {
            /*height: 105vh !important;*/
            height: 50vh !important;
        }
        //background-position:top;
        background-size: cover;
        background-repeat: no-repeat;
    }

    .bg-cover {
        background-size: cover !important;
        background-repeat: no-repeat !important;
        /*background-position: center;*/
        background-position: 66% 0;
        @media (min-width: 992px) {
            background-position: unset;
        }
    }

    i {
        color: black;
    }

    .swiper-button-next-1 {
        position: absolute;
        top: 0;
    }

    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
        //background-color: rgba(0, 0, 0, 0.4);
        //width: 4rem;
        bottom: 1.25rem !important;
        left: 0 !important;
        @media (min-width: 992px) {
            bottom: 2rem !important;
        }

        .swiper-pagination-bullet {
            border: 1px solid black !important;
            //background: white !important;
            left: 0 !important;
        }

        .swiper-pagination-bullet-active {
            background-color: gray !important;
        }

    }

    .wiper-pagination-bullet-active {
        background: gray !important;
    }

    .swiper-pagination {
        /*//width: 100%;*/
        /*width: 12rem;*/
        @media (min-width: 992px) {
            width: 12rem;
        }
    }
</style>
