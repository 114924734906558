<template>
    <div>
        <div class="container font-manrope-semi-bold mb-5">
            <h1 class="fl-eqh-c text-primary fs-lg text-center mt-5 ml-lg-5">Partner Agencies</h1>
            <div class="boxes mt-5 row ml-md-0">
                <template v-if="details === null || details.length === 0">
                    <p>Currently, No partners to list.</p>
                </template>
                <template v-else>
                    <div class="col-lg-4 col-md-6 mt-5" v-for="(item,i) in details" :key="i">
                        <div class="bs-10 pb-3">
                            <!--                            <div class="bg-contain w-100p" style="height: 10rem;" :style="backgroundImage(item.logo)"></div>-->
                            <img alt="logo" :src="item.logo" class="logo-img">

                            <div class="min-h-16re">
                                <h5 class="ml-3 mt-3">{{ item.name }}</h5>
                                <p class="parag m-3 font-manrope-medium mt-4">
                                    {{ item.description }}
                                </p>
                            </div>
                            <p @click="openSiteUrl(item.website_link)"
                                  class="cursor-pointer m-0 ml-3 mt-3 text-primary">View Website
                                <img alt="icon" src="../../../assets/img/Images/icons/pinned-icon.svg" class="ml-2">
                            </p>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'agencies',
    props: {
        details: {
            type: Array,
            default: null
        }
    },
    methods: {
        openSiteUrl (link) {
            window.open(link);
        },
        backgroundImage (image) {
            return { backgroundImage: 'url(' + image + ')' };
        }
    }
};
</script>

<style scoped>
h1 {
    color: #0E196D;
}

.garranto {
    width: 250px;
    /*height: 160px;*/
    height: 12rem;
}

.fs-lg {
    font-size: 2.1rem;
}

.min-h-14re {
    min-height: 14rem;
}

.min-h-16re {
    min-height: 16rem;
}

span {
    font-size: 19px;
}

.heading {
    text-align: center;
    color: #0E196D;
}

.box1 {
    /*border: 1px;*/
    /*width: 340px;*/
    min-height: 442px;
    /*border-radius: 2px;*/
}

.box2 {
    border: 1px;
    width: 340px;
    height: 442px;
    border-radius: 2px;
}

.box3 {
    border: 1px;
    width: 340px;
    height: 442px;
    border-radius: 2px;
}

h5 {
    color: #000000;
}

.boxes {
    /*justify-content: space-around;*/
    padding: 2px 27px;
    display: flex;
    flex-direction: row;
}

p {
    text-align: justify;
    line-break: anywhere;
}

.parag {
    color: #676767;
}

.garranto {
    width: 100%;
}

.logo-img {
    width: 100% !important;
    height: 10rem;
}

.aiimage {
    width: 308px;
    /*margin: -6px 0px;*/
}

.jpaimage {
    width: 301px;
}

.read {
    color: #0E196D;
}

.bs-10 {
    box-shadow: rgb(167 171 187) 0 4px 4px 0;
}

a {
    text-decoration: none;
    font-size: smaller;
}

@media (max-width: 768px) {
    /*.container {*/
    /*    margin-top: 335px;*/
    /*}*/
    .boxes {
        display: flex;
        flex-direction: column;
        padding: 0px 0px;
    }

    .box2 {
        margin-top: 10px;
    }

    .box3 {
        margin-top: 10px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .boxes {
        display: flex;
        flex-direction: row;
        padding: 0px 0px;
    }

    .box2 {
        margin-top: 0px;
    }

    .box3 {
        margin-top: 48px;
    }
}
</style>
