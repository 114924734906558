<template>
    <div>
        <section class="py-6">
            <div class="container px-lg-7">

                <div class="row fl-j-c">
                    <div class="col-lg-12">
                        <section-heading title="PRIVACY POLICY"></section-heading>
                    </div>
                    <div class="col mt-4">
                        <p class="text-justify">
                            Effective date: December 20, 2019 cyberfort.in ("us", "we", or "our") operates the
                            https://www.cyberfort.in website (the "Service"). This page informs you of our policies
                            regarding the collection, use, and disclosure of personal data
                            when you use our Service and the choices you have associated with that data.
                            We use your data to provide and improve the Service. By using the Service, you agree to the
                            collection
                            and use of information in accordance with this policy. Unless otherwise defined in this
                            Privacy
                            Policy,
                            terms used in this Privacy Policy have the same meanings as in our Terms and Conditions,
                            accessible
                            from
                            https://www.cyberfort.in

                        </p>
                        <h3 class="font-weight-bold head2 mt-lg-5 text-primary mb-lg-3 mb-3 fs-lg-6 font-manrope-bold"> Information Collection And Use</h3>
                        <p class="text-justify proxim-regular">
                            We collect several different types of information for various purposes to provide and
                            improve our
                            Service to you.

                        </p>

                        <h3 class="font-weight-bold mt-lg-5 text-primary mb-lg-3 mb-3 fs-lg-6 font-manrope-bold"> Types of Data Collected
                        </h3>
                        <p class="font-weight-bold text-dark-1 fs-2">Personal Data
                        </p>
                        <p class="text-justify proxim-regular">Lorem Ipsum is
                            simply
                            While using our Service, we may ask you to provide us with certain personally identifiable
                            information that can be used to contact or identify you ("Personal Data"). Personally
                            identifiable
                            information may include, but is not limited to:
                        </p>
                        <ol style="list-style: disc" class="proxim-regular pl-4">
                            <li> Email address</li>
                            <li> First name and last name</li>
                            <li> Phone number</li>
                            <li> Address, State, Province, ZIP/Postal code, City</li>
                            <li> Cookies and Usage Data</li>
                        </ol>
                        <p></p>
                        <p class="font-weight-bold text-dark-1 fs-2">Usage Data</p>
                        <p class="text-justify proxim-regular">We may also
                            collect
                            information how the Service is accessed and used ("Usage Data"). This Usage Data may include
                            information such as your computer's Internet Protocol address (e.g. IP address), browser
                            type,
                            browser version, the pages of our Service that you visit, the time and date of your visit,
                            the time
                            spent on those pages, unique device identifiers and other diagnostic data.
                            Tracking &amp; Cookies Data
                            We use cookies and similar tracking technologies to track the activity on our Service and
                            hold
                            certain information.
                            Cookies are files with small amount of data which may include an anonymous unique
                            identifier.
                            Cookies are sent to your browser from a website and stored on your device. Tracking
                            technologies
                            also used are beacons, tags, and scripts to collect and track information and to improve and
                            analyze
                            our Service.
                            You can instruct your browser to refuse all cookies or to indicate when a cookie is being
                            sent.
                            However, if you do not accept cookies, you may not be able to use some portions of our
                            Service.
                            Examples of Cookies we use:
                        </p>
                        <ol style="list-style: disc" class="proxim-regular pl-4">
                            <li><b>Session Cookies</b>- We use Session Cookies to operate our Service.
                            </li>
                            <li><b>Preference Cookies</b>- We use Preference Cookies to remember your preferences and
                                various
                                settings.
                            </li>
                            <li><b>Security Cookies</b> We use Security Cookies for security purposes.
                            </li>

                        </ol>
                        <p></p>
                        <h3 class="font-weight-bold mt-lg-5 text-primary mb-lg-3 mb-3 fs-lg-6 font-manrope-bold"> Use of Data</h3>

                        <p class="text-justify proxim-regular">
                            www.cyberfort.in uses
                            the collected data for various purposes:
                        </p>
                        <ol style="list-style: disc" class="proxim-regular pl-4">
                            <li> To provide and maintain the Service</li>
                            <li> To notify you about changes to our Service</li>
                            <li> To allow you to participate in interactive features of our Service when you choose to
                                do so
                            </li>
                            <li> To provide customer care and support</li>
                            <li> To provide analysis or valuable information so that we can improve the Service</li>

                            <li> To monitor the usage of the Service</li>
                            <li>

                                To detect, prevent and address technical issues
                            </li>
                        </ol>
                        <p></p>

                        <h3 class="font-weight-bold mt-lg-5 text-primary mb-lg-3 mb-3 fs-lg-6 font-manrope-bold">Transfer Of Data</h3>
                        <p class="text-justify proxim-regular"> Your
                            information,
                            including Personal Data, may be transferred to — and maintained on — computers located
                            outside of your state, province, country or other governmental jurisdiction where the data
                            protection
                            laws may differ than those from your jurisdiction.
                            If you are located outside India and choose to provide information to us, please note that
                            we
                            transfer
                            the data, including Personal Data, to India and process it there.
                            Your consent to this Privacy Policy followed by your submission of such information
                            represents your
                            agreement to that transfer.
                            www.cyberfort.in will take all steps reasonably necessary to ensure that your data is
                            treated
                            securely
                            and in accordance with this Privacy Policy and no transfer of your Personal Data will take
                            place to
                            an
                            organization or a country unless there are adequate controls in place including the security
                            of your
                            data and other personal information.
                        </p>

                        <h3 class="font-weight-bold mt-lg-5 text-primary mb-lg-3 mb-3 fs-lg-6 font-manrope-bold"> Disclosure Of Data
                            Legal Requirements</h3>
                        <p class="text-justify proxim-regular">
                            www.cyberfort.in may
                            disclose your Personal Data in the good faith belief that such action is necessary
                            to:</p>

                        <ol style="list-style: disc" class="proxim-regular pl-4 mb-3">
                            <li> To comply with a legal obligation</li>
                            <li> To protect and defend the rights or property of www.cyberfort.in</li>
                            <li> To prevent or investigate possible wrongdoing in connection with the Service</li>

                            <li> To protect the personal safety of users of the Service or the public</li>
                            <li> To protect against legal liability</li>

                        </ol>

                        <h3 class="font-weight-bold mt-lg-5 text-primary mb-lg-3 mb-3 fs-lg-6 font-manrope-bold"> Security Of Data</h3>
                        <p class="text-justify proxim-regular"> The security
                            of your
                            data is important to us, but remember that no method of transmission over the
                            Internet, or method of electronic storage is 100% secure. While we strive to use
                            commercially
                            acceptable
                            means to protect your Personal Data, we cannot guarantee its absolute security.</p>

                        <h3 class="font-weight-bold mt-lg-5 text-primary mb-lg-3 mb-3 fs-lg-6 font-manrope-bold"> Service Providers</h3>
                        <p class="text-justify proxim-regular"> We may employ
                            third
                            party companies and individuals to facilitate our Service ("Service Providers"), to
                            provide the Service on our behalf, to perform Service-related services or to assist us in
                            analyzing
                            how
                            our Service is used.
                            These third parties have access to your Personal Data only to perform these tasks on our
                            behalf and
                            are
                            obligated not to disclose or use it for any other purpose.</p>

                        <h3 class="font-weight-bold mt-lg-5 text-primary mb-lg-3 mb-3 fs-lg-6 font-manrope-bold"> Links To Other Sites</h3>
                        <p class="text-justify proxim-regular"> Our Service
                            may
                            contain links to other sites that are not operated by us. If you click on a third party
                            link, you will be directed to that third party's site. We strongly advise you to review the
                            Privacy
                            Policy of every site you visit.
                            We have no control over and assume no responsibility for the content, privacy policies or
                            practices
                            of
                            any third party sites or services.</p>

                        <h3 class="font-weight-bold mt-lg-5 text-primary mb-lg-3 mb-3 fs-lg-6 font-manrope-bold"> Children's Privacy</h3>
                        <p class="text-justify proxim-regular"> Our Service
                            does not
                            address anyone under the age of 18 ("Children").
                            We do not knowingly collect personally identifiable information from anyone under the age of
                            18. If
                            you
                            are a parent or guardian and you are aware that your Children has provided us with Personal
                            Data,
                            please
                            contact us. If we become aware that we have collected Personal Data from children without
                            verification
                            of parental consent, we take steps to remove that information from our servers.</p>

                        <h3 class="font-weight-bold mt-lg-5 text-primary mb-lg-3 mb-3 fs-lg-6 font-manrope-bold"> Changes To This Privacy Policy</h3>
                        <p class="text-justify proxim-regular"> We may update
                            our
                            Privacy Policy from time to time. We will notify you of any changes by posting the new
                            Privacy Policy on this page.
                            You are advised to review this Privacy Policy periodically for any changes. Changes to this
                            Privacy
                            Policy are effective when they are posted on this page.</p>

                        <h3 class="font-weight-bold mt-lg-5 text-primary mb-lg-3 mb-3 fs-lg-6 font-manrope-bold"> Contact Us</h3>
                        <p class="text-justify proxim-regular"> If you have
                            any
                            questions about this Privacy Policy, please contact us:</p>

                        <ol style="list-style: disc" class="proxim-regular pl-4">

                            <li> By email: info@cyberfort.in</li>
                            <li>By visiting this page on our website:<br> https://www.cyberfort.in/contact-form.html
                            </li>
                        </ol>

                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'PrivacyPolicyPage'
};
</script>

<style scoped>

</style>
