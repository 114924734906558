<template>
    <div class="py-5 pb-5 bg-white">
        <div class="container">
            <div class="col-lg-12">
                <section-heading size="xlg" title="CAREER" info="We're always on the lookout for the
                    talented people."></section-heading>
<!--                <p class="font-manrope-medium fs&#45;&#45;1 text-1 mb-5 mt-5 fl-x-cc">We're always on the lookout for the-->
<!--                    talented people.</p>-->
                <div class="row">
                    <div class="col-lg-12 ">
                        <h1 class="fl-x-cc font-manrope-medium fs-lg-5 fs-6 text-primary"> Open Positions</h1>
                    </div>
                </div>
                <div class="text-center text-primary py-7" v-if="dataLoading">
                    <loading-animation class="fs-10"/>
                    <p>Please wait while data is being loaded...</p>
                </div>
                <div class="row">
                    <div v-if="!dataLoading" class="col-lg-12 py-3 ">
                        <p v-if="details.length === 0">No any career courses to apply for now.</p>
                        <template>
                            <custom-accordion v-for="(item,i) in details" :key="i"
                                              :heading="item.title" :class="{'bb-black': i < details.length -1}"
                                              :description="item.title_description">
                                <template #default>
                                    <!--                                    <p class="text-primary fs-2 mt-4 font-manrope-semi-bold"></p>-->
                                    <template v-if="isJson(item.description)">
                                        <div v-for="(i, index) in JSON.parse(item.description)" :key="index">
                                            <h5 class="ml-lg-0 ml-4 mb-3 font-manrope-semi-bold text-primary"
                                                v-if="i.type==='h'">{{ i.value }}</h5>
                                            <div v-if="i.type==='d'"
                                                 class="para ml-lg-0 ml-4 mr-lg-6 mb-3 text-justify fs-lg-1 text-2 font-manrope-medium   ">
                                                <p>{{ i.value }}</p>
                                            </div>
                                            <div v-if="i.type==='b'"
                                                 class="ul ml-lg-3 ml-4 fs-lg-1 text-justify  mb-3 font-manrope-medium text-2 ">
                                                <ul>
                                                    <li v-for="(j, ind) in i.value" :key="ind">{{ j.value }}</li>
                                                </ul>
                                            </div>
                                        </div>
                                    </template>
                                    <p v-else class="text-2 fs-lg-1">{{ item.description }}</p>
                                    <div class="fl-x-cc mt-5 mb-4">
                                        <btn @click="$router.push('/Career-InnerPage/' + item.id + '/' + item.title)"
                                             text="Apply Now" border-radius="0" class="px-4"></btn>
                                    </div>
                                </template>
                            </custom-accordion>
                        </template>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'secure-axios';
import urls from '@/data/urls';
import CustomAccordion from '../components/ui/CustomAccordion';

export default {
    name: 'Career',
    components: { CustomAccordion },
    data () {
        return {
            dataLoading: false,
            details: []
        };
    },
    mounted () {
        this.loadData();
    },
    methods: {
        async loadData () {
            this.dataLoading = true;
            const response = await axios.get(urls.career.list);
            const json = response.data;
            if (json.status === true) {
                this.details = json.data;
                this.dataLoading = false;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        },
        isJson (str) {
            try {
                JSON.parse(str);
            } catch (e) {
                return false;
            }
            return true;
        }
    }
}
;
</script>

<style scoped>
.bg-white {
    background-color: white;
}
.bb-black {
    border-bottom: 1px solid slategray !important;
}
.txt-blue {
    color: #002D62;
}
</style>
