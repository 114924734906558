<template>
    <div v-if="details" class="c-animation mb-4 p-3" :class="{'right': cardPosition === 'right', 'left': cardPosition === 'left',
            'center': cardPosition === 'center'}">
        <div class="">
            <div class="img-contain" :style="{ backgroundImage: 'url(' + details.image + ');' }">
                <img :src="details.image" alt="service-img" class="w-h-100p trans-1">
            </div>
            <div class="px-3 pt-2 content-min-h">
                <h5 class="fs-lg-3 text-primary font-manrope-semi-bold fl-a-c fl-x min-h-3-5r">{{ details.title }}</h5>
                <p class="parag fs-lg-1 font-poppins-regular text-5">{{ details.title_description.slice(0, 299) }}
                    <span class="text-primary font-manrope-semi-bold" v-if="details.title_description.length > 359">...Read more</span>
                </p>
                <p class="text-primary"></p>
            </div>

        </div>
    </div>
    <div v-else class="c-animation mb-4 p-3 mx-1" :class="{'right': cardPosition === 'right', 'left': cardPosition === 'left',
            'center': cardPosition === 'center'}">
        <div class="">
            <img src="../../assets/img/Images/service1.jpg" class="img-fluid">
            <div class="px-3 py-2">
                <h5 class="fs-lg-3 text-primary font-manrope-semi-bold">Virtual CISO/DPO Services</h5>
                <p class="parag fs-lg--1 font-poppins-regular text-5">Lorem ipsum dolor sit amet, consectetur adipiscing
                    elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
                    quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure
                    dolor in reprehenderit Duis aute irure dolor in reprehenderitDuis aute irure dolor in
                    reprehenderitDuis aute irure dolor in reprehenderit</p>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'ProfesionalServicesCard',
    props: {
        details: {
            type: Object, default: null
        }
    },
    data () {
        return {
            cardPosition: ''
        };
    },
    mounted () {
        document.addEventListener('DOMContentLoaded', (event) => {
        });
        const that = this;
        document.addEventListener('mousemove', (event) => {
            if (event.clientX < window.innerWidth * 0.37) {
                that.cardPosition = 'left';
            } else if (event.clientX < window.innerWidth * 0.61 && event.clientX > window.innerWidth * 0.37) {
                that.cardPosition = 'center';
            } else {
                that.cardPosition = 'right';
            }
        });
    }
};
</script>

<style scoped lang="scss">
h4 {
    text-align: center;
}

.img-contain {
    width: 100% !important;
    height: 13rem !important;
    background-size: cover;
    transition: all ease-in-out 0.5s;
}

.trans-1 {
    transition: all ease-in-out 0.5s;
}

.content-min-h {
    min-height: 22rem;
}

.box1 {
    border: 1px;
    width: 345px;
    height: 438px;
    border-radius: 2px;
}

p {
    text-align: justify;
    //color: #6c757d;
    line-height: normal;
    //line-height: 24px;
}

.serviceimg {
    width: 345px;
}

.boxx {
    width: 300px;
    height: 200px;
}

.c-animation {
    z-index: 1;
    transition: all 0.5s ease-in-out;
    background: var(--bg-1);
    cursor: pointer;
    transform: scale(0.95);

    &.left {
        //&:hover {
        //    position: absolute;
        //    background: var(--bg-2);
        //    transform: scale(1.1);
        //    //transform: scale(1.2);
        //    z-index: 799 !important;
        //}

        @media (min-width: 1025px) {
            &:hover {
                position: absolute;
                background: var(--bg-2);
                //transform: scale(1.4) translateX(10%) translateY(15%);
                transform: scale(1.2) translateX(10%);
                //transform: scale(1.2);
                z-index: 799 !important;
            }
        }

    }

    &.right {
        transition: all ease-in-out 0.5s;

        //&:hover {
        //    position: absolute;
        //    background: var(--bg-2);
        //    transform: scale(1.1);
        //    //transform: scale(1.2);
        //    z-index: 799 !important;
        //}

        @media (min-width: 1025px) {
            &:hover {
                position: absolute;
                background: var(--bg-2);
                //transform: scale(1.4) translateX(-10%) translateY(15%);
                transform: scale(1.2) translateX(-10%);
                //transform: translateX(-10%);
                //width: 110%;
                //font-size: 0.1rem;
                //transform: scale(1.2);
                z-index: 799 !important;
            }
        }
    }

    &.center {
        transition: all ease-in-out 0.5s;

        //&:hover {
        //    position: absolute;
        //    background: var(--bg-2);
        //    transform: scale(1.1);
        //    //transform: scale(1.2);
        //    z-index: 799 !important;
        //}

        @media (min-width: 1025px) {

            &:hover {
                position: absolute;
                background: var(--bg-2);
                //transform: scale(1.4) translateY(15%);
                transform: scale(1.2);
                //transform: scale(1.2);
                ////transform: translateX(-10%);
                //width: 110%;
                //font-size: 0.1rem;
                z-index: 799 !important;
            }
        }
    }
}

</style>
