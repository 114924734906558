export default {
    props: {
        enterAnimation: { type: String, default: 'zoomIn' },
        exitAnimation: { type: String, default: 'zoomOut' }
    },
    computed: {
        enterTransitionClass () {
            return 'animated ' + this.enterAnimation;
        },
        exitTransitionClass () {
            return 'animated ' + this.exitAnimation;
        }
    }
};
