<template>
    <div>
        <div class="text-center text-primary py-7" v-if="dataLoading">
            <loading-animation class="fs-10"/>
            <p>Please wait while data is being loaded...</p>
        </div>
        <div v-else class="container">
            <ServicesPage></ServicesPage>
            <ServicesPage1 v-if="details" :details="details"></ServicesPage1>
            <ServicesPage2 v-if="details" :details="details.description"></ServicesPage2>
        </div>
        <p v-if="!details && !dataLoading" class="text-center py-7">
            No data available.
        </p>
    </div>
</template>

<script>
import ServicesPage from '../../components/Services/ServicesPage';
import ServicesPage1 from '../../components/Services/ServicesPage1';
import ServicesPage2 from '../../components/Services/ServicesPage2';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'TheServices',
    components: { ServicesPage2, ServicesPage1, ServicesPage },
    data () {
        return {
            dataLoading: false,
            details: '',
            id: this.$route.params.id
        };
    },
    watch: {
        $route () {
            this.loadData();
        }
    },
    mounted () {
        this.loadData();
    },
    methods: {
        async loadData () {
            const id = this.$route.params.id;
            this.dataLoading = true;
            const response = await axios.get(urls.website.serviceDetails, {
                params: { id: id }
            });
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
                this.dataLoading = false;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        }
    }
};
</script>

<style scoped>

</style>
