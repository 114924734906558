<template>
    <swiper class="swiper" :options="swiperOption" >
        <swiper-slide v-for="(rec, index) in schedule" v-bind:key="index"  class="">
            <inner-page-card class="font-manrope-medium " :heading="rec.month">
                <div class="pt-2 mh-17">
<!--                    <p class="text-center font-manrope-semibold  fs-3 text-black" data-swiper-parallax="-1000">-->
<!--                        {{ rec.month }}-->
<!--                    </p>-->
                    <pre></pre>
                    <div v-for="(i, k) in rec.date" v-bind:key="k"
                       class="text-center font-manrope-semi-bold text-black fs-lg-2 fs-1" data-swiper-parallax="-1000">
                        <p class="mb-0 fs-lg-2" v-html="i.split('|')[0]"></p>
                        <p class="fs-lg-1" v-html="i.split('|')[1]"></p>
                    </div>
                    <p class="text-center fs-lg--1" data-swiper-parallax="-1000">
                        <span class="text-danger">* </span>Closed schedule are shown by red colour
                    </p>
<!--              <i class="fl-x-cc"><p class =" font-manrope-extra-light text-black mt-2 fs&#45;&#45;2"> fully booked seats are denoted by red color</p> </i>-->
                </div>
            </inner-page-card>
        </swiper-slide>
        <div class="swiper-pagination swiper-pagination-white" slot="pagination">

        </div>
        <div class="swiper-button-prev swiper-button-white" slot="button-prev">
            <i class="fa fa-angle-left fa-2x text-white"></i>
        </div>
        <div class="swiper-button-next" slot="button-next"><i class="fa fa-angle-right fa-2x text-white"></i></div>
    </swiper>
</template>
<script> import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import InnerPageCard from '@components/ui/InnerPageCard';

export default {
    name: 'EventSwiper',
    props: {
        schedule: {
            type: Array, default: null
        }
    },
    title: 'Fade effect',
    components: { InnerPageCard, Swiper, SwiperSlide },
    data () {
        return {
            swiperOption: {
                parallax: true,
                loop: true,
                speed: 1500,
                spaceBetween: 30,
                effect: 'fade',
                pagination: { el: '.swiper-pagination', clickable: true },
                autoplay: { delay: 8000, disableOnInteraction: false },
                navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' }
            }
        };
    }
}; </script>
<style lang="scss" scoped>
.swiper {
    //height: 100vh;

    .swiper-slide {
        background-position: center;
        background-size: cover;;

        &.slide-1 {
            //background-image: url('../../assets/img/banner/banner1.jpg')
        }

        &.slide-2 {
            //background-image: url('../../assets/img/banner/banner2.jpg')
        }

        &.slide-3 {
            //background-image: url('../../assets/img/banner/banner3.jpeg')
        }
    }
}

.overlay-dark {
    background: rgba(0, 0, 0, 0.46);
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 100px
}

i {
    color: black;
}
.swiper-pagination{
    bottom:2rem !important;
}
.text-black {
    color: rgb(35, 43, 43);
}

.swiper-button-next-1 {
    position: absolute;
    top: 0;
}

.swiper-button-next {
    position: absolute !important;
    top: 34px !important;
    right: var(--spacer-3) !important;

    &::after {
        display: none;
    }
}

.swiper-button-prev {
    position: absolute !important;
    top: 34px !important;
    right: var(--spacer-3) !important;

    &::after {
        display: none;
    }
}
.mh-17{
    min-height:21rem!important;
}
@media(max-width: 768px) {
    .mh-17{
        min-height:27rem!important;
    }
}
</style>
