<template>
    <div class="container">
        <!--        <p class="text-primary mb-4 fs-5 font-manrope-semi-bold ">Course Details </p>-->
        <section-heading title="Course Details" align="left" bottom-tag-align="left"
                         class="font-manrope-semi-bold mb-5"></section-heading>
        <div class="d-sm-none">
            <div class="fl-x">
                <p class="w-15p text-primary fs-lg-2 font-manrope-semi-bold">Duration&nbsp;:&nbsp;</p>
                <p class=" fs-lg-2 font-manrope-semi-bold text-black ">{{ training.duration }}</p>
            </div>
        </div>
        <div class="d-lg-none d-md-none">
            <div class="fl-x">
                <p class=" text-primary fs-lg-2 wi-7r font-manrope-semi-bold">Duration&nbsp;:&nbsp;</p>
                <p class=" fs-lg-2 font-manrope-semi-bold text-black">{{ training.duration }}</p>
            </div>
        </div>
        <div class="d-lg-none d-md-none">
        <div class="fl-x">
            <p class=" text-primary fs-lg-2 wi-7r font-manrope-semi-bold">Fees&nbsp;:&nbsp;</p>
            <p class=" fs-lg-2 font-manrope-semi-bold text-black"> ₹ {{ training.course_fee }} plus {{ training.tax_percentage }}%
                GST(₹ {{ training.fees_number - training.course_fee }})</p>
        </div>
</div>
        <div class="d-sm-none">
        <div class="fl-x">
            <p class=" text-primary fs-lg-2 w-15p font-manrope-semi-bold">Fees&nbsp;:&nbsp;</p>
            <p class=" fs-lg-2 font-manrope-semi-bold text-black"> ₹ {{ training.course_fee }} plus {{ training.tax_percentage }}%
                GST(₹ {{ training.fees_number - training.course_fee }})</p>
        </div>
</div>
        <div class="d-lg-none d-md-none">
        <div class="fl-x">
            <p class=" text-primary fs-lg-2 wi-7r font-manrope-semi-bold">Type&nbsp;:&nbsp;</p>
            <p class=" fs-lg-2 font-manrope-semi-bold text-black">{{ training.type_of_training }}</p>
        </div>
            </div>
        <div class="d-sm-none">
        <div class="fl-x">
            <p class=" text-primary fs-lg-2 w-15p font-manrope-semi-bold">Type&nbsp;:&nbsp;</p>
            <p class=" fs-lg-2 font-manrope-semi-bold text-black">{{ training.type_of_training }}</p>
        </div>
            </div>
        <div class="bg-blue p-2 mt-4">
            <p class="text-white font-manrope-semi-bold fs-lg-2 mt-2 fl-x-cc">Total Fees &nbsp;:&nbsp; &#8377; {{ training.fees_number }}/- </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CourseDetails',
    props: {
        training: {
            type: Object, default: null
        }
    }
};
</script>

<style scoped>
.bg-blue {
    background-color: #0a0a58;
}

.text-white {
    color: white
}
.text-black{
    color: #323232;

}
.wi-7r{
    width: 0;
}
.wi-8r{
    width: 0;
}
@media(max-width: 768px){
    .wi-7r{
        width:7rem;

    }
    .wi-8r{
    width: 8rem;
}
}
</style>
