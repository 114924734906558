<template>
    <div>
        <div class="container">
            <section-heading size="lg" title="WHAT OUR CLIENTS SAY!"
                             class="mb-5 font-manrope-semi-bold"></section-heading>
            <div class="cardss  bs-10 ml-lg-9">
                <div class="col-lg-2 col-md-3"><img src="../../assets/web/home/rectangle.svg" class="mt-lg-4 client">
                </div>
                <div class="col-lg-10 col-md-9"><p
                    class="ml-lg-5 mt-lg-5 font-poppins-regular clientpara m-lg-4 mt-md-5"><img
                    src="../../assets/web/home/coma1.svg"
                    class="mr-lg-1">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus
                    venenatis,
                    lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis
                    leo, vel purus<img src="../../assets/web/home/coma2.svg" class="space" style="margin-left: 240px">
                </p><h4
                    class="ml-lg-5 font-manrope-bold text-primary " style="font-size: medium">Sanjay Krishna</h4></div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ClientSay'
};
</script>

<style scoped>
.cardss {
    /*width: 680px;*/
    /*height: 202px;*/
    border: 1px;
    border-radius: 12px;
    display: flex;
}

.client {
    width: 140px;
    margin-top: 30px;
}

.clientpara {
    font-size: smaller;
}

.bs-10 {
    box-shadow: rgb(167 171 187) 0 1px 10px 0;
}
.space{
        margin-left: 240px;
}
p {
    color: #050505;
}
@media (max-width: 765px) {
    .cardss {
    /*        width: 354px;*/
    /*height: 426px;*/
        display: flex;
        flex-direction: column;
    }

    .space{
          margin-left: 32px;
    }

    .client {
        width: 180px;
        margin-left: 70px;
    }

    .clientpara {
        line-break: anywhere;
    }
}
@media (min-width: 768px) and (max-width: 1024px) {
    .cardss {
        width: 680px;
    }
}
</style>
