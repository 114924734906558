<template>
    <div class="partners">
        <div class="text-center text-primary py-7" v-if="dataLoading">
            <loading-animation class="fs-10"/>
            <p>Please wait while data is being loaded...</p>
        </div>
        <div v-else>
            <certificate :banner="banner"></certificate>
            <agencies :details="details"></agencies>
        </div>
    </div>
</template>

<script>
import certificate from '../components/Partners/certificate';
import Agencies from '../components/Partners/partners/agencies';
import axios from 'secure-axios';
import urls from '../data/urls';

export default {
    name: 'Partners',
    components: { Agencies, certificate },
    data () {
        return {
            dataLoading: false,
            details: null,
            banner: null
        };
    },
    mounted () {
        this.loadData();
    },
    methods: {
        async loadData () {
            this.dataLoading = true;
            const response = await axios.get(urls.partner.partnersPage);
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
                if (json.partner_banner.length !== 0) {
                    this.banner = json.partner_banner[0].image;
                }
                this.dataLoading = false;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        }
    }
};
</script>

<style scoped>
.partners {
    background-color: #FFFFFF;
}

</style>
