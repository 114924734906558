import { render, staticRenderFns } from "./RecentBlogs.vue?vue&type=template&id=77aa5216&scoped=true&"
import script from "./RecentBlogs.vue?vue&type=script&lang=js&"
export * from "./RecentBlogs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77aa5216",
  null
  
)

export default component.exports