<template>
    <div>
        <div class="container">
            <div class="row">
                <div class="col-lg-12 mt-4">
                    <div class="one">
                        <h3 class="font-manrope-bold fs-lg-5 mb-5 text-primary mt-md-n6 mt-n6">{{ details.title }}</h3>
                    </div>
                    <div class="graphic pr-5 mt-5 mb-3 bg-cover top-img" style=""
                         :style="backgroundImage(details.image)">
                        <!--                        <img :src="details.image" class="img-fluid">-->
                    </div>
                    <div class="para mb-3 font-manrope-regular bb-black mt-5">
                        <p class="text-justify">{{ details.title_description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesPage1',
    props: {
        details: {
            type: Object, default: null
        }
    },
    methods: {
        backgroundImage (image) {
            return { backgroundImage: 'url(' + image + ')' };
        }
    }
};
</script>

<style scoped lang="scss">
    h3 {
        color: midnightblue;
    }

    /*img {*/
    /*    width: 50rem;*/
    /*    height: 32rem;*/
    /*}*/

    @media (max-width: 765px) {
        .graphic img {
            width: 19rem;
            height: 15rem;
        }

        .services-img {
            width: 100% !important;
        }
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        img {
            width: 41rem;
        }
    }

    p {
        color: #323232;
    }

    li {
        color: #323232;
        line-height: 2rem;
    }

    .top-img {
        width: 100% !important;
        height: 15rem;
        @media (min-width: 992px) {
            width: 93% !important;
            height: 32rem;
        }
    }
</style>
