<template>
    <div>
        <div class="  py-5">
            <div class="container">
                <div class="px-lg-9 col-12  col-lg-10">
                    <p class="font-manrope-bold fs-lg-7 fs-3 pl-lg-3 text-primary mb-5 ">
                        {{ title }}
                    </p>
                </div>
                <div class="col-lg-8 col-12 px-lg-9 ">
                    <p class="font-manrope-semi-bold fs-lg-3 pl-lg-3 text-5 mb-lg-5">SUBMIT YOUR APPLICATION</p>
                </div>

                <div class="fl-x-cc">
                    <div class="col-12 col-lg-8">
                        <validation-observer slim v-slot="{handleSubmit}" ref="formValidator">
                            <form role="form" @submit.prevent="handleSubmit(onSubmit)">
                                <div class="row">
                                    <div class="col-4 col-lg-4 fl-x-cl  ">
                                        <label class=" text-primary font-manrope-medium fs-2  ">Full Name</label>
                                    </div>
                                    <div class="col-lg-8 col-8">
                                        <validated-input v-model="model.name" :rules="rules.fullName" size="m"
                                                         border-radius="0" name="Name" :disabled="submitLoading"
                                                         class="py-1 font-manrope-medium c-input-primary "></validated-input>
                                    </div>

                                    <div class="col-4 col-lg-4 fl-x-cl  ">
                                        <label class=" text-primary fs-2 fl-x-cc  font-manrope-medium">Email</label>
                                    </div>
                                    <div class="col-lg-8 col-8">
                                        <validated-input v-model="model.email" :rules="rules.email" size="m"
                                                         border-radius="0" type="email" name="E-Mail"
                                                         :disabled="submitLoading"
                                                         class="py-1 font-manrope-medium  c-input-primary "></validated-input>
                                    </div>
                                    <div class="col-4 col-lg-4 fl-x-cl">
                                        <label class=" text-primary fs-2 font-manrope-medium">Contact Number</label>
                                    </div>
                                    <div class="col-lg-8 col-8">
                                        <validated-input v-model="model.contact_number" :rules="rules.contactNumber"
                                                         size="m" type="number" :disabled="submitLoading"
                                                         border-radius="0" name="Contact number"
                                                         class="py-1 font-manrope-medium c-input-primary "></validated-input>

                                    </div>

                                    <div class="col-4 col-lg-4 fl-x-cl">
                                        <label class=" text-primary fs-2 font-manrope-medium">CV/Resume</label>
                                    </div>

                                    <div class="col-8 col-md-4 col-lg-6 mt-0 ">
                                        <custom-file-input v-model="model.resume" placeholder="Resume" acc
                                                           size="m" name="Resume" border-radius="0"
                                                           :disabled="submitLoading"
                                                           class="c-resume-attach c-file-input text-primary pr-lg-8 fs-1 font-manrope-medium  mt-3">
                                            <template #prepend>
                                                <i class="fa fa-paperclip"></i>
                                            </template>
                                        </custom-file-input>

                                    </div>

                                    <div class="col-12 col-lg-12 mt-4 ">
                                        <validated-text-area v-model="model.add_info" :rules="rules.additionalInfo"
                                                             size="m" :disabled="submitLoading"
                                                             label="Additional information" border-radius="0"
                                                             class="fs-2 pb-2 c-input   font-manrope-medium text-primary place-holder-l-grey text-area-c c-input-primary"
                                                             name="Add Info"></validated-text-area>
                                    </div>
                                    <div class="col-12">
                                        <div class="mt-4">
                                            <mark class="fl-x-cc grey p-1 mb-0">
                                                <div class="row fl-te-c">
                                                    <div class="col-10">
                                                        <img class="img-fluid" v-if="model.captcha_URL"
                                                             :src="model.captcha_URL + '200/'"
                                                             alt="Captcha"/>
                                                    </div>
                                                    <div @click="loadCaptcha" class="col-2">
                                                        <loading-animation v-if="captchaLoading" class="text-primary"/>
                                                        <i v-else class="fa fa-refresh"/>
                                                    </div>
                                                </div>
                                            </mark>
                                        </div>
                                        <div class="col-12 col-lg-6 p-0 mt-3">
                                            <validated-input label="Captcha*" name="Captcha" border-radius="0"
                                                             v-model="model.captcha" :rules="{required: true}"
                                                             :disabled="submitLoading"
                                                             class="c-input-primary"></validated-input>
                                        </div>
                                        <div class="col-12 col-lg-6 p-0 mb-3 text-3 text-danger" v-if="captchaError">
                                            {{ captchaError }}
                                        </div>
                                        <div class="mt-2">
                                            <btn text="Submit Application" type="submit" loading-text="Submitting..."
                                                 border-radius="0" :loading="submitLoading" :disabled="submitLoading"/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </validation-observer>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
// import FormSuccessModal from '@components/Career-Form/Form-Success-Modal';
import CustomFileInput from '../../components/lego-custom/CustomFileInput';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'CareerInnerPage',
    components: { CustomFileInput },
    data () {
        return {
            submitLoading: false,
            captchaLoading: false,
            title: this.$route.params.title,
            model: {
                career: this.$route.params.id,
                name: '',
                email: '',
                contact_number: '',
                resume: '',
                add_info: '',
                captcha_URL: '',
                captcha_key: '',
                captcha: ''
            },
            captchaError: '',
            rules: {
                fullName: {
                    required: true
                },
                email: {
                    required: true
                },
                contactNumber: {
                    required: true,
                    min: 10,
                    max: 10
                },
                resume: {
                    required: false
                },
                additionalInfo: {
                    required: false
                }
            }
        };
    },
    mounted () {
        this.loadCaptcha();
    },
    methods: {
        async loadCaptcha () {
            this.captchaLoading = true;
            const response = await axios.get(urls.getCaptcha);
            const json = response.data;
            if (json.error === false) {
                this.model.captcha_key = json.key;
                this.model.captcha_URL = json.image_url;
                this.model.captcha = '';
                setTimeout(() => {
                    this.captchaLoading = false;
                }, 1000);
            } else {
                this.$notify('Could not load captcha, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            setTimeout(() => {
                this.captchaLoading = false;
            }, 1000);
        },
        async onSubmit () {
            this.submitLoading = true;
            this.captchaFormError('', true);
            const formValidator = this.$refs.formValidator;
            const response = await axios.form(urls.career.applyNow, this.model);
            const json = response.data;
            if (json.error === false) {
                this.submitLoading = false;
                this.$notify('Applied Successfully', 'Success', {
                    type: 'success'
                });
                await this.$router.push('/career/');
            }
            if (json.error === true) {
                formValidator.setErrors(json.errors);
            }
            if (json.captcha === false) {
                this.submitLoading = false;
                this.captchaFormError(json, false);
            }
            this.submitLoading = false;
        },
        captchaFormError (json, clear) {
            if (clear) {
                this.captchaError = '';
            } else {
                this.captchaError = 'Invalid Captcha';
                this.model.captcha_URL = json.image_url;
                this.model.captcha_key = json.key;
            }
        }
    }
};
</script>

<style scoped lang="scss">
i:first-child, i.i-inline:first-child {
    margin-left: 1px;
    transform: rotate(225deg);
}

i {
    color: darkblue;
}
</style>
