<template>
    <div class="text-center text-primary py-7" v-if="dataLoading">
        <loading-animation class="fs-10"/>
        <p>Please wait while data is being loaded...</p>
    </div>
    <div v-else class="">
        <banner-slider :details="details.banners"></banner-slider>
        <professional-services-section v-if="details.services.length!==0"
                                       :details="details.services"></professional-services-section>
        <management-section v-if="details.management.length!==0" :details="details.management"></management-section>
        <div class="mt-lg-4">
            <services-section v-if="details.services.length!==0" :details="details.services"></services-section>
        </div>
        <gallery-section class="mt-lg-6" v-if="details.gallery.length!==0" :details="details.gallery"></gallery-section>
        <training-section class="mb-lg-5" v-if="details.training.length!==0"
                          :details="details.training"></training-section>
        <solutions-and-consultancy class="mt-lg-6" v-if="details.solution_consultancy_service.length!==0"
                                   :details="details.solution_consultancy_service"></solutions-and-consultancy>
        <what-clients-say-section class="mt-lg-3" v-if="details.what_our_client_says.length!==0"
                                  :details="details.what_our_client_says"></what-clients-say-section>
    </div>
</template>

<script>
// import BaseHeadingWithInfo from '@components/ui/BaseHeadingWithInfo';
// import SectionHeading from '@components/ui/SectionHeading';
import BannerSlider from '../components/home-components/BannerSlider';
import ManagementSection from '../components/home-components/ManagementSection';
import ProfessionalServicesSection from '../components/home-components/ProfessionalServicesSection';
import ServicesSection from '../components/home-components/ServicesCard';
import GallerySection from '../components/home-components/GallerySection';
// import Second from '@components/Solutions/second';
import TrainingSection from '../components/home-components/sections/TrainingSection';
import SolutionsAndConsultancy from '../components/home-components/sections/SolutionsAndConsultancy';
import axios from 'secure-axios';
import urls from '../data/urls';
import WhatClientsSaySection from '../components/home-components/sections/WhatClientsSaySection';

export default {
    name: 'Home',
    components: {
        WhatClientsSaySection,
        SolutionsAndConsultancy,
        // Second,
        TrainingSection,
        GallerySection,
        ServicesSection,
        ProfessionalServicesSection,
        ManagementSection,
        BannerSlider
    },
    data () {
        return {
            dataLoading: false,
            details: {
                banners: [],
                services: [],
                gallery: [],
                management: [],
                // second: [],
                training: [],
                solution_consultancy_service: [],
                what_our_client_says: []
            }
        };
    },
    mounted () {
        this.loadData();
    },
    methods: {
        async loadData () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.homePage);
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        }
    }
};

</script>
<style scoped>

</style>
