<template>
    <div>
        <div class="container mb-5" v-if="details">
<!--            <section-heading size="lg" title="ABOUT" info="Lorem ipsum dolor sit amet, consectetur-->
<!--                adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."></section-heading>-->
<!--            <p class="mb-5 mt-5 aboutpara">Lorem ipsum dolor sit amet, consectetur-->
<!--                adipiscing elit, sed <br>do eiusmod tempor incididunt ut labore et dolore magna aliqua. </p>-->
            <h1 class="head2 mt-lg-5 text-primary mb-lg-0 mb-3 fs-lg-6 font-manrope-bold">Company Overview</h1>
            <p v-if="details[0].company_overview_para_1" class="para2 mt-lg-5">
                {{ details[0].company_overview_para_1 }}
            </p>
            <p v-if="details[0].company_overview_para_2" class="para3">
                {{ details[0].company_overview_para_2 }}
            </p>
            <h1 class="head3 mt-lg-7 text-primary mb-lg-0 mb-3 fs-lg-6 font-manrope-bold">Who We Are</h1>
            <p v-if="details[0].who_we_are_para_1" class="para2 mt-lg-5">
                {{ details[0].who_we_are_para_1 }}
            </p>
            <p v-if="details[0].who_we_are_para_2" class="para3">
                {{ details[0].who_we_are_para_2 }}
            </p>

        </div>
    </div>
</template>

<script>

export default {
    name: 'about',
    props: {
        details: {
            type: Array, default: null
        }
    },
    components: {}
};
</script>

<style scoped>
.head2 {
    text-align: center;
    color: yellow;
}

hr.new1 {
    border-top: 1px solid darkblue;
    width: 95px;
    margin-left: 505px;
}

.para {
    text-align: center;
}

.head3 {
    text-align: center;
    color: yellow;
}

.para2 {
    text-align: center;
    text-align: justify;
}

.para3 {
    text-align: center;
    text-align: justify;
}

/*p {*/
/*    color: #323232;*/
/*}*/

@media (max-width: 765px) {
    hr.new1 {
        border-top: 1px solid darkblue;
        width: 95px;
        margin-left: 140px;
    }

    .head1 {
        text-align: center;
        color: midnightblue;
    }

    .aboutpara {
        padding: 0px 54px;
    }
}
</style>
