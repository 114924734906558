<template>
    <div>
        <div class="container py-5">
            <div class="row">
                <div class="col-12">
                    <section-heading size="lg" title="BLOG"
                                     info="A platform to share the information happening at cybersecurity industry provided by our technical team. "></section-heading>
                </div>
            </div>
            <p v-if="details.recent_blog_posts.length === 0 && details.all_blog_posts.length === 0 && !dataLoading"
               class="text-center py-7">
                No blogs available.
            </p>
            <div class="text-center text-primary py-7" v-if="dataLoading">
                <loading-animation class="fs-10"/>
                <p>Please wait while data is being loaded...</p>
            </div>
            <div v-else class="row">
                <div class="col-12">
                    <recent-blogs-section v-if="details.recent_blog_posts.length!==0"
                                          :details="details.recent_blog_posts"></recent-blogs-section>
                </div>
                <div class="col-12 mt-6">
                    <all-blog-posts v-if="details.all_blog_posts.length!==0"
                                    :details="details.all_blog_posts"></all-blog-posts>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RecentBlogsSection from '../../components/blogs/sections/RecentBlogsSection';
import AllBlogPosts from '../../components/blogs/sections/AllBlogPosts';
import urls from '../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'RecentBlogs',
    components: { AllBlogPosts, RecentBlogsSection },
    data () {
        return {
            dataLoading: false,
            details: null
        };
    },
    mounted () {
        this.loadData();
    },
    methods: {
        async loadData () {
            this.dataLoading = true;
            const that = this;
            await axios.get(urls.blog.blogPage).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.details = json;
                    that.dataLoading = false;
                } else {
                    that.$notify('Could not load data, Please try again later', 'Error', {
                        type: 'danger'
                    });
                }
                that.dataLoading = false;
            });
        }
    }
};
</script>

<style scoped>

</style>
