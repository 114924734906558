<template>
    <div>
        <div class="row">
            <div class="col-lg-12 mt-2">
                <div class="graphic ml-lg-4 mt-2 mb-5 mb-lg-5">
                    <img v-if="!image" class="img-fluid" src="../../assets/img/Images/Rectangle 54.png">
                    <img v-else class="img-fluid" :src="image">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'cyberattacks',
    props: {
        image: {
            type: String,
            default: ''
        }
    }
};
</script>
<style scoped>
img {
    width: 55rem;
    height: 32rem;
}

@media screen and (max-width: 768px) {
    img {
        width: 100%;
        height: 80%;
    }
}
</style>
