import { render, staticRenderFns } from "./ceo.vue?vue&type=template&id=bfe5a1a4&scoped=true&"
import script from "./ceo.vue?vue&type=script&lang=js&"
export * from "./ceo.vue?vue&type=script&lang=js&"
import style0 from "./ceo.vue?vue&type=style&index=0&id=bfe5a1a4&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bfe5a1a4",
  null
  
)

export default component.exports