<template>
    <div class="row cursor-pointer" @click="openBlogArticlePage">
        <div v-if="data" class=" col-12">
            <div v-if="data.image" class="mb-1 img-fluid" :class="{'graphic': size === 'lg'}">
                <img class="img-fluid" :src="data.image">
            </div>
            <div v-if="data.author_name" class="fl-x mt-">
                <p class="font-manrope-medium fs--1 text-2 mr-2" :class="{'mb-0': size === 'lg'}">
                    {{ data.author_name }}</p>
                <li class="font-manrope-medium fs--1 text-2 ml-2">{{ data.create_date }}</li>
            </div>
            <template>
                <div class="h-6re">
                    <p class="text-primary bb-black"
                       :class="{'fs-lg-4 mt-2 font-manrope-semi-bold' : size === 'lg', 'font-manrope-medium fs-lg-2': size === 'sm'}">
                        {{ data.title }}</p>
                    <p class="text-2 fs-lg-1">
                        {{ data.title_description }}
                    </p>
                </div>
            </template>
            <div class="fl-x">
                <p class="p-btn  font-manrope-medium mr-2 mr-lg-0  mb-lg-5 mb-3">Cyber security</p>
                <p class="p-btn font-manrope-medium ml-lg-2 mb-lg-5 mb-3" v-if="size === 'lg'">Technology</p>
                <!--                <div class="">-->
                <!--                    <btn design="basic-a" border-radius="4" size="sm" class="font-manrope-medium ">download</btn>-->
                <!--                </div>-->

                <p v-if="data.category" class="p-btn-2  font-manrope-medium  mr-2 ml-2 mb-lg-5 mb-3">
                    {{ data.category }}</p>
                 <div>
                    <btn @click="openBlogArticlePage" design="basic-a" border-radius="4" size="sm"
                         class="font-manrope-medium px-4 mr-3 ">view
                    </btn>
                </div>

            </div>

        </div>

    </div>

</template>

<script>
export default {
    name: 'RecentBlogPost1',
    props: {
        data: {
            type: Array,
            default: null
        },
        size: {
            type: String,
            default: 'sm'
        }
    },
    methods: {
        openBlogArticlePage () {
            this.$router.push('/blog-article-article/' + this.data.id + '/details/');
        }
    }
};
</script>

<style scoped>
.graphic img {
    /*width: 55rem;*/
    /*height: 32rem;*/
    height: 25rem;
}

/*.h-6re {*/
/*    height: 6rem;*/
/*}*/

.p-btn {
    border: 1px solid black;
    border-radius: 16px;
    font-size: 0.85rem;
    color: #3D3F40;
    padding-left: 9px;
    padding-right: 9px;
    padding-top: 4px;
    padding-bottom: 6px;
}
.p-btn-2 {
    border: 1px solid black;
    border-radius: 16px;
    font-size: 0.85rem;
    color: #3D3F40;
    padding-left: 19px;
    padding-right: 19px;
    padding-top: 4px;
    padding-bottom: 4px;
}

@media screen and (max-width: 768px) {
    .graphic img {
        width: 100%;
        height: 80%;
    }
}
</style>
