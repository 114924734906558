<template>
    <div class="min-h-100">
        <div class="container py-4">
            <div class="row">
                <div class="col-12">
                    <section-heading class="mb--2" size="lg" title="GALLERY"></section-heading>
                </div>
                <div class="col-12 mt-lg-41">
                    <div class="pos-r">
                        <swiper v-if="details.length===0" class="swiper swiper-primary pb-5" :options="swiperOption">
                            <swiper-slide v-for="i in 3" :key="i">
                                <img src="../../assets/web/home/christina-wocintechchat-com-faEfWCdOKIg-unsplash.jpg"
                                     class="img-fluid c-animation" :class="{'right': cardPosition === 'right', 'left': cardPosition === 'left',
                                    'center': cardPosition === 'center'}" alt="">
                            </swiper-slide>
                        </swiper>
                        <swiper v-else class="swiper swiper-primary pt-lg-6 pb-lg-6 pb-6 pt-5" :options="swiperOption">
                            <swiper-slide v-for="(i, index) in details" :key="index" class="bg-2">
                                <div class="c-animation px-3 px-md-0 px-lg-0" :class="{'right': cardPosition === 'right', 'left': cardPosition === 'left',
                                    'center': cardPosition === 'center'}">
                                    <!--                                    <div class="w-100p" v-bind:style="{ backgroundImage: 'url(' + i.image + ')' }">-->
                                    <!--                                    </div>-->
                                    <!--                                    v-bind:style="{ backgroundImage: 'url(' + image + ')' }"-->
                                    <img :src="i.image" class="w-h-100p image-item" style=""
                                         :class="{'right': cardPosition === 'right', 'left': cardPosition === 'left',
                                    'center': cardPosition === 'center'}" alt="">
                                    <!--                                    <div :style="BackgroundImage(i.image)" class="w-h-100p bg-cover-center"-->
                                    <!--                                         :class="{'right': cardPosition === 'right', 'left': cardPosition === 'left',-->
                                    <!--                                    'center': cardPosition === 'center'}"></div>-->
                                </div>

                            </swiper-slide>
                        </swiper>

                        <div class="swiper-btn swiper-button-prev pr-md-4 pl-md-3  px-2 fs--1"
                             slot="button-prev">
                            <img src="../../assets/web/Icons/arrow-left.svg" alt="">
                        </div>
                        <div class="swiper-btn swiper-button-next px-md-4 px-2" slot="button-next">
                            <img src="../../assets/web/Icons/arrow-right.svg" alt="">
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
    name: 'GallerySection',
    props: {
        details: {
            type: Array, default: null
        }
    },
    components: { Swiper, SwiperSlide },
    data () {
        return {
            cardPosition: '',
            swiperOption: {
                autoHeight: true,
                slidesPerView: 3,
                spaceBetween: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                mousewheel: false,
                parallax: true,
                centeredSlides: false,
                // autoplay: true,
                autoplay: { delay: 8000, disableOnInteraction: false },
                navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
                breakpoints: {
                    1200: {
                        speed: 1500,
                        slidesPerView: 3
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                        speed: 1000
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                        speed: 800
                    },
                    640: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    320: { slidesPerView: 1, spaceBetween: 0, speed: 800 }
                }

            },
            items: []
        };
    },
    mounted () {
        const that = this;
        document.addEventListener('mousemove', (event) => {
            // console.log('mousemove', event.clientX, event.clientY);
            // console.log('width', window.innerWidth);
            // if (event.clientX < window.innerWidth / 2) {
            if (event.clientX < window.innerWidth * 0.37) {
                that.cardPosition = 'left';
                // console.log('card-pos', that.cardPosition);
            } else if (event.clientX < window.innerWidth * 0.61 && event.clientX > window.innerWidth * 0.37) {
                that.cardPosition = 'center';
            } else {
                that.cardPosition = 'right';
                // console.log('card-pos', that.cardPosition);
            }
            // else if(event.clientX < window.innerWidth * 0.61 && event.clientX > windonw.innerWidth * 0.37)
        });
    },
    methods: {
        BackgroundImage (image) {
            return { backgroundImage: 'url(' + image + ')' };
        }
    }

};
</script>

<style scoped lang="scss">
    main {
        //background: #f2f2f2;
    }

    .mt-lg-41 {
        margin-top: 1.8rem !important;
    }

    //.min-h-100 {
    //    @media(min-width: 992px) {
    //        min-height: 7vh !important;
    //    }
    //}

    .w-h-100p {
        width: 100% !important;
        height: 100% !important;
    }

    .image-item {
         height: 14rem !important;
        @media (min-width: 992px) {
            height: 17rem !important;
        }
    }

    .swiper-btn {
        position: absolute;
        z-index: 799 !important;
        color: rgba(128, 128, 128, 0.7) !important;
        //border: 1px solid rgba(128, 128, 128, 0.9);
        //background-color: var(--color-white);
        //padding: var(--spacer-2);
        font-size: 0.9rem !important;
        color: var(--color-primary) !important;

        &.swiper-button-prev {
            left: -1rem !important;
            @media (min-width: 768px) and (max-width: 1024px) {
                left: -2rem !important;
            }
            @media (min-width: 992px) {
                left: -2rem !important;
               /* margin-top:-4rem !important;*/
            }
        }

        &.swiper-button-next {
            right: -0.9rem !important;
            @media (min-width: 768px) and (max-width: 1024px) {
                right: -2.5rem !important;
            }
            @media (min-width: 992px) {
                right: -2.5rem !important;
                /*margin-top:-4rem !important;*/
            }
        }
    }

    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, 0);
        width: unset;
        padding: 3px 25px;
        background: #2929293b;
        border-radius: 15px;
    }

    .c-animation {
        z-index: 1;
        transition: all 0.5s ease-in-out;
        /*background: var(--bg-1);*/
        cursor: pointer;
        /*height: 25rem;*/
        /*@media (min-width: 992px) {*/
        /*    height: 22rem;*/
        /*}*/

        &.left {
            //&:hover {
            //    position: absolute;
            //    background: var(--bg-2);
            //    transform: scale(1.2);
            //    //transform: scale(1.2);
            //    z-index: 799 !important;
            //}

            @media (min-width: 992px) {
                &:hover {
                    position: absolute;
                    background: var(--bg-2);
                    transform: scale(1.5) translateX(10%);
                    //transform: scale(1.2);
                    z-index: 799 !important;
                }
            }

        }

        &.right {
            //&:hover {
            //    position: absolute;
            //    background: var(--bg-2);
            //    transform: scale(1.2);
            //    //transform: scale(1.2);
            //    z-index: 799 !important;
            //}

            @media (min-width: 992px) {
                &:hover {
                    position: absolute;
                    background: var(--bg-2);
                    transform: scale(1.5) translateX(-10%);
                    //transform: scale(1.2);
                    z-index: 799 !important;
                }
            }
        }

        &.center {
            //&:hover {
            //    position: absolute;
            //    background: var(--bg-2);
            //    transform: scale(1.1);
            //    //transform: scale(1.2);
            //    z-index: 799 !important;
            //}

            @media (min-width: 992px) {

                &:hover {
                    position: absolute;
                    background: var(--bg-2);
                    transform: scale(1.5);
                    //transform: scale(1.2);
                    z-index: 799 !important;
                }
            }
        }
    }

    .swiper-button-prev {
        @media (min-width: 992px) {
            left: -3.8rem !important;
        }

        &::after {
            display: none;
        }
    }

    .swiper-button-next {
        &::after {
            display: none;
        }
    }
</style>
