<template>
    <div>
        <template v-if="data">
            <p class="ml-lg-4 mb-3">{{data.title_description}}</p>
            <div v-for="(i, index) in JSON.parse(data.description)" :key="index">

                <h4 class="ml-lg-6 ml-4 mb-3" v-if="i.type==='h'">{{ i.value }}</h4>
                <div v-if="i.type==='d'"
                     class="para  ml-4 mb-3 font-manrope-regular  bb-black">
                    <p>{{ i.value }}</p>

                </div>

                <div v-if="i.type==='b'"
                     class="ul  ml-4  mb-3 font-manrope-regular  bb-black">
                    <ul>
                        <li v-for="(j, ind) in i.value" :key="ind">{{ j.value }}</li>
                    </ul>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'loremipsum',
    props: {
        data: {
            type: Object,
            default: null
        }
    }
};
</script>

<style scoped>
p {
    text-align: justify;
}
</style>
