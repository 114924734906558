<template>
    <div class="ceo">
        <div v-for="(i, index) in details" :key="index">
            <div v-if="index % 2 !== 0 && index!==0" class="container">
                <div class="row mt-lg-6 mb-5">
                    <div class=" col-12 col-lg-8 mt-4 mt-md-4 mt-lg-0 order-2 order-lg-1">
                        <h2 class="head1 text-primary fs-lg-7 font-manrope-bold">{{ i.heading }}</h2>
                        <p class="para mt-lg-3">{{ i.para_1 }}</p>
                        <p class="para2">{{ i.para_2 }}</p>
                    </div>
                    <div class="col-12 col-lg-4 mt-lg-0 order-1 order-lg-2">
                        <div class="w-100p h-100p bg-cover side-img-h img" :style="backgroundImage(i.image)"></div>
                        <!--                    <img :src="i.image" class="img about2-img">-->
                    </div>
                </div>

            </div>
            <div v-else class="container">
                <div class="row mb-lg-5 mb-3">
                    <div class="col-12 col-lg-4 mt-lg-0 pr-lg-0">
                        <div class="w-100p h-100p bg-cover-center side-img-h img" :style="backgroundImage(i.image)"></div>
                        <!--                        <img :src="i.image" class="about2-img img ml-2 ml-lg-0 mt-md-3 ml-md-8">-->
                    </div>
                    <div class="col-12 col-lg-8 mt-4 mt-md-4 mt-lg-0">
                        <h2 class="head2 ml-lg-5 text-primary mb-md-3 mt-lg-3 fs-lg-7 font-manrope-bold">{{ i.heading }}</h2>
                        <p class="para3 mt-lg-0 ml-lg-5 m-md-0  mt-lg-3">{{ i.para_1 }}</p>
                        <p class="para4 ml-lg-5 m-md-0 mt-md-3">{{ i.para_2 }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ceo',
    props: {
        details: {
            type: Array, default: null
        }
    },
    methods: {
        backgroundImage (image) {
            return { backgroundImage: 'url(' + image + ')' };
        }
    }
};
</script>
<style scoped lang="scss">
    /*.container {*/
    /*    margin-left: 91px;*/
    /*}*/

    .img {
        border: 1px;
        border-radius: 20px;
        background-color: #B9C4E1;
    }

    .para {
        text-align: justify;
    }

    .para2 {
        text-align: justify;
    }

    .para3 {
        margin: 25px -40px;
    }

    .para4 {
        margin: 25px -40px;
    }

    /*.head1 {*/
    /*    color: midnightblue;*/
    /*}*/

    p {
        color: #323232;
        text-align: justify;
    }

    .about2-img {

        width: 360px;
        height: 360px;
    }

    @media (max-width: 765px) {
        .about-1 {
            display: flex;
            flex-direction: column-reverse;
        }

        /*.about2-img {*/
        /*    width: 100% !important;*/
        /*}*/

        /*.container {*/
        /*    margin-left: 0px;*/
        /*}*/

        .head1 {
            text-align: center;
            margin-bottom: 18px;
        }

        .head2 {
            text-align: center;
            margin-bottom: 18px;
            /*margin-left: 150px;*/
        }

        /*.img {*/
        /*    width: 335px;*/
        /*    margin-bottom: 28px;*/
        /*}*/

        .para3 {
            margin: 25px -7px;
        }

        .para4 {
            margin: 25px -7px;
        }
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        /*.container {*/
        /*    margin-left: 0px;*/
        /*}*/

        /*.img {*/
        /*    margin-left: 124px;*/
        /*}*/

        .head1 {
            text-align: center;
        }

        .about-2 {
            display: flex;
            flex-direction: column-reverse;
        }

        .head2 {
            text-align: center;
        }
    }

    .img-contain {
        position: relative;
        /*height: 15rem !important;*/
        background-position: center;
        background-repeat: no-repeat !important;
        background-size: cover;

        img {
            width: 100%;
        }

        &:hover {

        }

    }

    .side-img-h {
        height: 15rem !important;
        @media (min-width: 992px) {
            height: 100% !important;
        }
    }
</style>
