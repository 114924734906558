<template>
    <div class="text-center text-primary py-7" v-if="dataLoading">
        <loading-animation class="fs-10"/>
        <p>Please wait while data is being loaded...</p>
    </div>
    <div v-else class="py-5">
<!--        <inner-banner heading="ABOUT US" :image="bgImageSample" content="Lorem ipsum dolor sit amet, consectetur-->
<!--                adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."></inner-banner>-->
        <section-heading title="ABOUT US"></section-heading>
        <div v-if="details" class="about mt-lg-6">
            <p v-if="details.about_us.length === 0 && details.about_us_profile.length === 0 && !dataLoading" class="text-center py-7">
                No data available.
            </p>
            <abouts v-if="details.about_us.length !== 0" :details="details.about_us"></abouts>
            <ceo v-if="details.about_us_profile.length !== 0" :details="details.about_us_profile"></ceo>
        </div>
    </div>
</template>
<script>
import abouts from '../components/abouts';
import Ceo from '../components/ceo';
import axios from 'secure-axios';
import urls from '../data/urls';
// import InnerBanner from '../components/ui/InnerBanner';
import bgImageSample from '../assets/web/bg/banner31.jpg';
export default {
    name: 'About',
    components: { Ceo, abouts },
    data () {
        return {
            dataLoading: false,
            details: {
                aboutUs: [],
                aboutUsProfile: [],
                bgImageSample: bgImageSample
            }
        };
    },
    mounted () {
        this.loadData();
    },
    methods: {
        async loadData () {
            this.dataLoading = true;
            const response = await axios.get(urls.website.aboutUsPage);
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        }
    }
};
</script>

<style scoped>
    .about {
        background-color: #FFFFFF;
    }
</style>
