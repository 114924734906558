<template>
    <div class="mb-3 border-r-2 b-1 pos-r">
        <div v-if="linkBack" @click="$router.go(-1)"
                     class="text-white text-decoration-none cursor-pointer pos-a-lc c-circle fl-x-cc">
<!--            <img src="../../assets/web/icons/chevron-left-24.png" alt="">-->
        </div>
        <head-tag :color="color" :align-end="alignEnd" class="mb-0 pt-0">
            <template #header>
                <div class="pos-r">
                    <div class="fl-y fl-a-c">
                        <div v-if="heading !== ''" class="">
<!--                            <span><i :class="icon" class="fs-lg-2 text-white mr-2"></i></span>-->
                            <h4 class="font-inter-medium text-center c-heading d-inline fs-lg-2 mb-0 py-0"
                                :class="{'c-bullet': headerBullet}">
                                {{ heading }}</h4>
                        </div>
                        <div v-if="heading === ''">
                            <slot name="header-area"></slot>
                        </div>
                        <div class="d-block">
                            <slot name="note"></slot>
                        </div>
                    </div>
                </div>
            </template>
            <template #buttons>
                <slot name="buttons"></slot>
            </template>
        </head-tag>
        <section class="fs--1 py-4 font-inter-regular pb-5" :class="{'px-lg-4 px-3': horzPadding, 'c-scroll ':scrollbar}">
            <slot></slot>
        </section>
    </div>
</template>
<script>

// import HeaderTag from '@components/ui/HeaderTag';
import HeadTag from '@components/ui/HeadTag';

export default {
    name: 'InnerPageCard',
    components: { HeadTag },
    props: {
        heading: {
            type: String,
            default: ''
        },
        color: {
            type: String,
            default: ''
        },
        icon: {
            type: String,
            default: ''
        },
        scrollbar: {
            type: Boolean,
            default: true
        },
        headerBullet: {
            type: Boolean,
            default: false
        },
        alignEnd: {
            type: Boolean,
            default: false
        },
        horzPadding: {
            type: Boolean,
            default: true
        },
        linkBack: {
            type: Boolean,
            default: false
        }
    }
};
</script>
<style scoped lang="scss">
.c-bullet {
    position: relative;

    &::before {
        content: '';
        position: absolute;
        left: -0.8rem;
        top: 50%;
        transform: translateY(-50%);
        width: 0.4rem;
        height: 0.4rem;
        background-color: var(--color-success);
        border-radius: 50%;
    }

}

.c-heading{
    transition: all 0.3s ease-in-out;
}

.c-circle {
    width: 24px;
    height: 24px;
    //background: var(--color-secondary-600);
    background: #1a41a2 !important;
    border-radius: 50%;
    &.circle-dark{
        background: #1a41a2 !important;
    }
}
.c-scroll{
    overflow-y:auto;
    overflow-x:hidden;
    max-height: 35vh;
    //min-height: 50vh;
}
</style>
