<template>
    <div>
        <div class="container">
            <div class="text-center text-primary py-7" v-if="dataLoading">
                <loading-animation class="fs-10"/>
                <p>Please wait while data is being loaded...</p>
            </div>
            <div v-else>
                <cybersecurity :data="details"></cybersecurity>
                <cyberattacks :image="details.image"></cyberattacks>
                <loremipsum :data="details"></loremipsum>
                <lastmile :data="relatedBlogs"></lastmile>
            </div>

        </div>
    </div>
</template>

<script>
import cybersecurity from '../../components/blogs/cybersecurity';
import cyberattacks from '../../components/blogs/cyberattacks';
import loremipsum from '../../components/blogs/loremipsum';
import lastmile from '../../components/blogs/lastmile';
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'BlogArticlePage',
    components: { lastmile, loremipsum, cyberattacks, cybersecurity },
    data () {
        return {
            dataLoading: false,
            details: null,
            relatedBlogs: null,
            id: null
        };
    },
    watch: {
        '$route.params.id' (newId) {
            this.id = newId;
            this.loadData();
        }
    },
    created () {
        this.id = this.$route.params.id;
        this.loadData();
    },
    mounted () {
        this.loadData();
    },
    methods: {
        async loadData () {
            this.dataLoading = true;
            const response = await axios.form(urls.blog.blogInnerPage, { id: this.id });
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
                this.relatedBlogs = json.related_blog;
                this.dataLoading = false;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
        }
    }
};
</script>

<style scoped>

</style>
