<template>
    <section class="py-5 full-width" :class="{pt9:$route.name !== 'Home'}">
        <div class="container">
            <p class="font-manrope-medium fs--1 text-black cursor-pointer" @click="$router.push('/photo-gallery/')">&nbsp;&nbsp;Photo Gallery&nbsp;<i
                class="fa fa-chevron-right"></i><span
                class="fs--1 text-primary font-manrope-medium">{{ title }}</span></p>
            <section-heading class="font-manrope-semi-bold text-center "
                             :title="title"></section-heading>
            <div class="row mb-5" data-aos="fade-up" data-aos-duration="1500">
                <div class="col-12 fl-x fl-j-c">
                    <div class="pr-2 mr-lg-6 font-weight-bold lt-gp-1 cursorn-pointer afterFulWidth">
                    </div>

                </div>
            </div>
            <transition name="fade" mode="in-out">
                <div class="text-center text-primary py-7" v-if="dataLoading">
                    <loading-animation class="fs-10"/>
                    <p>Please wait while data is being loaded...</p>
                </div>
                <div class="row mb-5" v-if="!dataLoading && imgObjects.length!==0">
                    <CoolLightBox
                        :items="imgItems"
                        :index="index"
                        @close="index = null">
                    </CoolLightBox>
                    <template v-for="(i, imageIndex) in imgObjects">
                        <transition name="zoom" mode="in-out" v-bind:key="imageIndex">
                            <div class="col-12 col-md-6 col-lg-4 p-lg-0 mt-4 img-box"
                                 @click="index = imageIndex">
                                <div class="gal-img-contain mr-lg-4 mr-0 " :style="banner(i.image)">
                                    <div class="overlay-dark"></div>
                                </div>
                                <i class="fa fa-search-plus fa-2x photo-icon"></i>
                                <!--                                <h4 class="text-primary mt-2 fs-lg-1 font-manrope-extra-bold">{{title}}</h4>-->
                            </div>
                        </transition>
                    </template>
                </div>
                <div class="row mb-5" v-if="!dataLoading && imgObjects.length===0">
                    <p class="col-12 text-center">No images have been uploaded for the event yet.</p>
                </div>
            </transition>
        </div>
    </section>
</template>
<script>
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'Gallery-InnerPage',
    components: {},
    data () {
        return {
            activeCateg: 'all',
            items: '',
            imgItems: [],
            images: [],
            imgObjects: [],
            index: null,
            dataLoading: false,
            details: [],
            id: this.$route.params.id,
            title: this.$route.params.title

        };
    },
    mounted () {
        this.loadData();
    },
    methods: {
        banner (image) {
            return { backgroundImage: 'url(' + image + ')' };
        },
        async loadData () {
            this.dataLoading = true;
            const that = this;
            await axios.form(urls.gallery.detailsPage, { id: this.id }).then(function (response) {
                const json = response.data;
                that.dataLoading = false;
                if (json.error === false) {
                    that.details = json.data;
                    if (that.details.length === 0) {
                        that.imgObjects = that.images;
                    } else {
                        that.imgObjects = that.details;
                    }
                    that.imgObjects.forEach((item, i) => {
                        that.imgItems.push(item.image);
                    });
                } else {
                    that.$notify('Could not load data, Please try again later', 'Error', {
                        type: 'danger'
                    });
                }
            });
        }
    }
};
</script>
<style scoped lang="scss">

    .img-box {
        position: relative;
        cursor: pointer;
        padding-bottom: 0 !important;

        .photo-icon {
            visibility: hidden;
            height: unset;
            display: none;
        }

        &:hover {
            .photo-icon {
                transition: all ease-in-out 300ms;
                position: absolute;
                top: 43%;
                left: 44%;
                bottom: 0;
                color: white;
                color: white;
                cursor: pointer;
                visibility: visible;
                height: unset;
                display: block;
            }

            .overlay-dark {
                visibility: visible;
            }
        }
    }

    .gal-img-contain {
        position: relative;
        height: 15rem !important;
        background-position: center;
        background-repeat: no-repeat !important;
        background-size: cover;

        img {
            width: 100%;
        }

        &:hover {

        }

    }

    .fade-left-enter-active, .fade-left-leave-active {
        transition: opacity .5s;
    }

    .fade-left-enter, .fade-left-leave-to /* .fade-left-leave-active below version 2.1.8 */
    {
        opacity: 0;
    }

    .fade-right-enter-active, .fade-right-leave-active {
        transition: opacity .5s;
    }

    .fade-right-enter, .fade-right-leave-to /* .fade-left-leave-active below version 2.1.8 */
    {
        opacity: 0;
    }

    .zoom-enter-active, .zoom-leave-active {
        transform: scale(1);
        opacity: 1;
        transition: all .5s;
    }

    .zoom-enter, .zoom-leave-to /* .fade-left-leave-active below version 2.1.8 */
    {
        opacity: 0;
        transform: scale(0.6);
    }

    .afterFulWidth {
        transition: all 0.5s;

        &::after {
            width: 200%;
            opacity: 1;
            transition: all 0.5s;
        }

        &:hover {
            color: var(--color-danger);
        }
    }

    .mt9 {
        padding-top: 9rem;
    }

    .overlay-dark {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.48);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 0 100px;
        bottom: 20 !important;
        visibility: hidden;
    }

    i {
        width: 10px
    }

    .text-black {
        color: rgb(35, 43, 43);
    }
</style>
