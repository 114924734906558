<template>
    <div class="fl-x fl-j-c px-lg-8">
        <div v-if="data" class="row">
            <div class="col-lg-12 pt-4">
                <h3 class="text-primary font-manrope-medium fs-lg-4">Related Blogs</h3>
            </div>
            <div class="col-12 col-lg-6 pt-3" v-for="(item,i) in data.slice(0,2)" :key="i">
                <div class="box-1">
                    <img :src="item.image">
                    <h6 class="font-manrope-semi-bold mb-3 fl-x fl-a-c mt-4">
                        <span>{{ item.author_name }}</span>
                        <img src="../../assets/web/home/dot icon.svg" alt="">
                        <span>{{ item.create_date }}</span>
                    </h6>
                    <h3 class="font-manrope-medium mb-3 text-primary fs-lg-4 ">{{ item.title }}</h3>
                    <p class="mb-5 mb-lg-0mt-4">{{ item.title_description }}</p>
                    <div class="pl-2">
                        <!--                        <btn border-radius="4" design="basic-a" class="font-manrope-medium" size="sm">download</btn>-->

                        <btn @click="openBlogArticlePage(item)" border-radius="4" design="basic-a"
                             class="font-manrope-medium px-4" size="sm">view
                        </btn>

                    </div>

                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'lastmile',

    props: {
        data: {
            type: Array,
            default: null
        }
    },
    data () {
        return {

        };
    },
    methods: {
        openBlogArticlePage (item) {
            this.$router.push('/blog-article-article/' + item.id + '/details/');
        }
    }
};
</script>

<style scoped>

.box-1 h3 {
    text-align: justify;
}

.box-1 h6 {
    text-align: justify;
}

.box-1 p {
    text-align: justify;
    /*padding:10px;*/
}

/*.box-2 h3{*/
/*    text-align: justify;*/
/*}*/
.box-2 h6 {
    text-align: justify;
}

.box-2 p {
    text-align: justify;
}

.box-1 img {
    width: 100%;
}

.box-2 img {
    width: 102%;
    height: 285px;
}

/*.row {*/
/*    padding: 0px 113px;*/
/*}*/

.box-1 h6 img {
    width: 24px;
    height: 5px;
}

.box-2 h6 img {
    width: 24px;
    height: 5px;
}

@media screen and (max-width: 768px) {
    img {
        width: 100%;
        height: 80%;
    }

    .box-1 img {
        width: 100%;
        height: 80%;
    }

    .box-2 img {
        width: 100%;
        height: 314px
    }

    .box-1 h3 {
        padding: 1px 12px;
    }

    .box-2 h3 {
        padding: 1px 12px;
    }

    .row {
        padding: 0px 0px;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    .box-1 img {
        width: 100%;
        height: 50%;
    }

    .box-2 img {
        width: 100%;
        height: 100%;
    }
}
</style>
