<template>
    <div class="container">
        <div>
            <div class="fl-x fl-a-s">
                <span class=" fl-x fl-a-c  mt-5 font-manrope-medium mr-2"><span>Blog</span><i
                    class="fa fa-angle-right"></i></span>
                <span class=" fl-x  mt-5 font-manrope-bold  text-primary " v-if="data.title">{{ data.title }}</span>
            </div>
            <h2 v-if="data.title" class="mt-4 font-manrope-bold text-primary pb-3 bb-black  fs-lg-8">
                {{ data.title }}</h2>
        </div>
        <div class="fl-x">
            <div class=" fl-x fl-a-c ml-2 font-manrope-bold fs-2 text-primary" v-if="data.author_name">
                {{ data.author_name }}<span
                class="ml-2"> <img src="../../assets/img/Images/blog/line-img.svg" alt=""></span>
            </div>
            <div class=" fl-x fl-a-c ml-lg-3 ml-2 font-manrope-bold">{{ data.create_date }}
                <span class="ml-2"></span>
            </div>
        </div>
        <hr class="ml-2">
    </div>
</template>
<script>
export default {
    name: 'cybersecurity',
    props: {
        data: {
            type: Object,
            default: null
        }
    }
};
</script>

<style scoped>

hr {
    width: 80%;
    margin-left: 0px;
    border: 0px;
    border: 1px solid #837f7f;
}

@media (max-width: 768px) {
    hr {
        width: 100%;
        margin-left: 0px;
        border: 0px;
        border: 1px solid #837f7f;
    }
}
</style>
