<template>
    <div class="card-1 cursor-pointer pb-3 py-lg-0 bs-10 mx-1 md-min-h-40vh" style="">
        <div class="row">
            <div class="col-lg-4 fl-x-cc">
                <div class="w-100p card-img-max-h ">
                    <img alt="image" :src="data.image" class="w-100p h-100p">
                </div>
            </div>
            <div class="col-lg-8 px-lg-0 mt-lg-2">
                <div class="p-2 pl-3 pl-lg-2 card-contents pt-3 pt-lg-2">
                    <h3 class="font-manrope-bold fs-lg-5 text-primary">{{ data.title }}</h3>
                    <p class="font-manrope-regular pr-lg-1 fs-lg--1 mb-2">{{ data.content }}...</p>
                    <h5 class="font-manrope-semi-bold fs-lg-2 text-primary">Schedule</h5>
                    <p class="font-manrope-regular fs-lg--1 mb-0">{{ data.schedule.start_date }} - {{ data.schedule.end_date }}
                        ({{ data.schedule.location }})</p>
                </div>
                <div class="pl-3 p-lg-2">
                   <btn @click="$router.push('/course/' + data.title + '/' + data.id)" text="Learn More" border-radius="0"
                         class="px-4"></btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TrainingCardSliderItem',
    props: {
        data: {
            type: Object,
            default: null
        }
    },
    methods: {
        setBackgroundImage (image) {
            return { backgroundImage: 'url(' + image + ')' };
        }
    }
};
</script>

<style scoped lang="scss">
    .card-1 {
        background: var(--bg-1);
        transition: all 0.5s ease-in-out;
        //transform: scale(0.95);
        //transform: scale(0.95);
        @media (min-width: 1025px) {
            &:hover {
                position: absolute;
                z-index: 799 !important;
                background: var(--bg-2);
                //position: absolute;
                //transform: scale(1.15) translateY(-5%);
                //transform: scale(1.2) translateY(-2%);
                //transform: scale(1.25);
                transform: scale(1.29);
            }
        }
    }

    .bs-10 {
        box-shadow: rgb(167, 171, 187) 0 5px 10px 0;
    }

    /*img {*/
    /*    width: 100%;*/
    /*    height: 95%;*/
    /*}*/

    .topbtn {
        width: 40px;
        margin-left: 505px;
    }

    .botombtn {
        width: 40px;
        margin-left: 505px;
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        .md-min-h-40vh {
            min-height: 40vh !important;
        }
        .md-min-h-15vh {
            min-height: 15vh !important;
        }
    }
    .card-img-max-h{
        height: 15.75rem !important;
        /*@media (min-width: 992px) and (max-width: 1024px){*/
        /*    height: 15.75rem !important;*/
        /*}*/
        @media (min-width: 992px) {
            /*max-height: 13.65rem !important;*/
            height: 100% !important;
            max-height: 13.55rem !important;
        }
    }
    .card-contents{
        height: 13rem;
        @media (min-width: 992px) {
            height: unset;
        }
    }
</style>
