<template>
    <div>
        <modal class="c-modal modal-body modal-header  header-primary" :no-close-on-backdrop="true"
               title="Training and Certification"
               ref="popUpModal" width="40r" header-color="primary">
            <div class="text-center text-primary py-7" v-if="loading">
                <loading-animation class="fs-10"/>
                <p>Please wait while data is being loaded...</p>
            </div>
            <div v-if="training && !loading">
                <div class="">
                    <div class="col-12 col-lg-12 card  bg-clr">
                        <p class="  font-manrope-bold text-primary text-center fs-2 mt-3 mb-5">
                            {{ training.title }}
                        </p>
                        <div>

                            <div v-if="training.duration" class="">
                                <div class="fl-x">
                                    <p class=" text-primary fs-1 c-w-10v font-manrope-semi-bold">
                                        Duration&nbsp;:&nbsp;</p>
                                    <p class=" fs-1 font-manrope-semi-bold text-grey">{{ training.duration }}</p>
                                </div>
                            </div>
                            <div class="fl-x">
                                <p class=" text-primary fs-1 c-w-10v font-manrope-semi-bold">Fees&nbsp;:&nbsp;</p>
                                <p class=" fs-1 font-manrope-semi-bold text-grey"> ₹ {{ training.course_fee }}
                                    plus {{ training.tax_percentage }}% GST (₹ {{
                                        training.fees_number - training.course_fee
                                    }})</p>
                            </div>

                            <div class="fl-x">
                                <p class=" text-primary fs-1 c-w-10v font-manrope-semi-bold">Type&nbsp;:&nbsp;</p>
                                <p class=" fs-1 font-manrope-semi-bold text-grey">{{ training.type_of_training }}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bg-blue p-1 mt-4">
                    <p class="text-white font-manrope-semi-bold text-center fs-1   mt-3 fl-x-cc">Total Fees &nbsp;:&nbsp;
                        &#8377;&nbsp;{{ training.fees_number }}/- </p>
                </div>
                <s-form class="mt-4 col-lg-12 pb-4" role="form" @submit="onSubmit" ref="formValidator">
                    <div class="row">
                        <div class="col-lg-12 col-12">
                            <validated-select v-model="model.event_training_schedule" :rules="{required: true}"
                                              size="m" :disabled="registerLoading" border-radius="1"
                                              placeholder=" Select date" :options="scheduleOptions"
                                              class="py-1 text-primary font-manrope-medium mb-0 d-input-career-c c-input-select-blue"></validated-select>
                        </div>

                        <div class="col-lg-12 col-12">
                            <validated-select v-model="model.country" :rules="{required: true}" size="m"
                                              border-radius="1" :disabled="registerLoading" placeholder="Select country"
                                              class="py-1 text-primary font-manrope-medium mb-0 d-input-career-c c-input-select-blue "
                                              :options="countriesOptions"></validated-select>
                        </div>

                        <div class="col-lg-12 col-12">
                            <validated-input v-model="model.city" :rules="{required: true}" size="m"
                                             border-radius="1" :disabled="registerLoading" placeholder="Enter City"
                                             class="py-1 mb-0 font-manrope-medium d-input-career-c "></validated-input>
                        </div>
                        <div class="col-lg-12 col-12">
                            <validated-input v-model="model.name" :rules="{required: true}" size="m"
                                             border-radius="1" :disabled="registerLoading" placeholder="Name"
                                             class="py-1 mb-0 font-manrope-medium d-input-career-c "></validated-input>
                        </div>
                        <div class="col-lg-12 col-12">
                            <validated-input size="m" border-radius="1" placeholder="Email" v-model="model.email"
                                             :rules="{required: true}" type="email" name="Email"
                                             :disabled="registerLoading"
                                             class="py-1 mb-0 font-manrope-medium d-input-career-c "></validated-input>
                        </div>
                        <div class="col-lg-12 col-12">
                            <validated-input size="m" border-radius="1" placeholder="Phone number"
                                             :disabled="registerLoading"
                                             v-model="model.phone_number" type="number" name="Phone number"
                                             class="py-1  mb-0 font-manrope-medium d-input-career-c "
                                             :rules="{required: true, max:10, min:10}"></validated-input>
                        </div>

                    </div>
                    <div class="mt-3 fl-x-cc">
                        <mark class="grey text-center p-1  mb-0">
                            <div class="row fl-te-c">
                                <div class="col-10">
                                    <img class="img-fluid" v-if="model.captcha_URL"
                                         :src="model.captcha_URL + '200/'"
                                         alt="Captcha"/>
                                </div>
                                <div class="col-2">
                                    <loading-animation v-if="captchaLoading" class="text-primary"/>
                                    <i @click="loadCaptcha" v-else class="fa fa-refresh"/>
                                </div>
                            </div>
                        </mark>
                    </div>
                    <div class="fl-x-cc mt-0">
                        <validated-input placeholder="Captcha" v-model="model.captcha" :rules="{required: true}"
                                         class="font-manrope-medium text-primary d-input-career-c"></validated-input>
                        <br/>
                    </div>
                    <div class="text-3 text-center fs--1 text-danger" v-if="captchaError">{{ captchaError }}</div>
                    <div class=" mt-4 mb-4 ">

                        <div class="fl-x-cc">
                            <btn text="Register" :loading="registerLoading" border-radius="0" color="danger"
                                 class="px-4 font-manrope-regular" loading-text="Registering..."></btn>
                        </div>
                    </div>

                </s-form>
            </div>
        </modal>
        <modal is-custom-dialog header-color="primary" ref="paymentConfirm" width="60r" :no-close-on-backdrop="true"
               title="Training Payment"
               class="custom-modal">
            <div class="row">
                <validated-input class="col-6" :disabled="true" border-radius="0" v-model="payModel.name"
                                 label="Name"></validated-input>
                <validated-input class="col-6" :disabled="true" border-radius="0" v-model="payModel.email"
                                 label="Email"></validated-input>
                <validated-input class="col-6" :disabled="true" border-radius="0" v-model="payModel.phone"
                                 label="Phone Number"></validated-input>
                <validated-input class="col-6" :disabled="true" border-radius="0" v-model="payModel.training_name"
                                 label="Training Name"></validated-input>
                <validated-input class="col-6" :disabled="true" border-radius="0" v-model="payModel.date"
                                 label="Date"></validated-input>
                <validated-input class="col-6" :disabled="true" border-radius="0" v-model="payModel.amount"
                                 label="Fee"></validated-input>
            </div>
            <div class="p-4 text-center">
                <p>Are you sure you want to continue payment section ?</p>
                <button @click="payToRedirect"
                        class="lego-btn btn-bg-c btn-basic btn-color-primary round-1 bs-1 bs-1-h p-1 mt-2">
                    <div class="btn-bg px-5 Proxima-Semibold">Pay Now</div>
                </button>
            </div>
        </modal>

        <custom-modal is-custom-dialog ref="paymentRedirect" width="40r" :no-close-on-backdrop="true"
                      title="Payment" class="custom-modal" header-color="primary">
            <RedirectPage :model="payModel"/>
        </custom-modal>
    </div>
</template>

<script>
import RedirectPage from '../../views/components/services/training/RedirectPage';
import axios from 'secure-axios';
import urls from '../../data/urls';

export default {
    name: 'TrainingModal',
    components: { RedirectPage },
    props: {
        training_data: {
            type: Object, default: null
        }
    },
    data () {
        return {
            captchaLoading: false,
            registerLoading: false,
            loading: false,
            training: null,
            scheduleOptions: [],
            countriesOptions: [],
            model: {
                event_training_schedule: '',
                country: '',
                city: '',
                name: '',
                phone_number: '',
                email: '',
                captcha_URL: '',
                captcha_key: '',
                captcha: ''
            },
            captchaError: '',
            regID: '',
            payModel: {
                amount: '',
                firstname: '',
                name: '',
                email: '',
                phone: '',
                training_name: '',
                action: '',
                address1: '',
                address2: '',
                city: '',
                country: '',
                furl: '',
                hash_string: '',
                hash: '',
                key: '',
                lastname: '',
                merchant_key: '',
                productinfo: '',
                service_provider: '',
                state: '',
                surl: '',
                txnid: '',
                udf1: '',
                udf2: '',
                udf3: '',
                udf4: '',
                udf5: '',
                zipcode: ''
            }
        };
    },

    computed: {
        name () {
            return this.$route.params.training;
        },
        training_id () {
            return this.$route.params.id;
        }
    },

    watch: {
        name () {
            this.loadData();
        }
    },

    mounted () {
        this.loadData();
    },

    methods: {
        showPopUpModal () {
            this.$refs.popUpModal.show();
        },
        onSubmit () {
            const that = this;
            that.registerLoading = true;
            that.captchaFormError('', true);
            axios.form(urls.registration, that.model).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.regID = json.reg_id;
                    that.registerLoading = false;
                    that.registerStatus();
                    that.$refs.popUpModal.close();
                } else {
                    if (json.errors) {
                        that.registerLoading = false;
                        that.formErrors(json);
                    } else {
                        that.registerLoading = false;
                        that.captchaFormError(json, false);
                    }
                }
            }).catch(function (err) {
                that.registerLoading = false;
                console.log('error', err);
            });
        },
        formErrors (json) {
            this.$refs.formValidator.setErrors(json.errors);
        },
        captchaFormError (json, clear) {
            if (clear) {
                this.captchaError = '';
            } else {
                this.captchaError = 'Invalid Captcha';
                this.model.captcha_URL = json.image_url;
                this.model.captcha_key = json.key;
            }
        },
        registerStatus () {
            const that = this;
            that.loading = true;
            axios.form(urls.paymentInitials, { reg_id: that.regID }).then(function (response) {
                const json = response.data;
                if (json.error === false) {
                    that.setModel(json.payu);
                    that.loading = false;
                    that.$refs.paymentConfirm.show();
                } else {
                    that.loading = false;
                }
            }).catch(function (err) {
                console.log('error : ', err);
            });
        },
        async loadCaptcha () {
            this.captchaLoading = true;
            const response = await axios.get(urls.getCaptcha);
            const json = response.data;
            if (json.error === false) {
                this.model.captcha_key = json.key;
                this.model.captcha_URL = json.image_url;
                this.model.captcha = '';
                setTimeout(() => {
                    this.captchaLoading = false;
                }, 1000);
            } else {
                this.$notify('Could not load captcha, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            setTimeout(() => {
                this.captchaLoading = false;
            }, 1000);
        },

        async loadData () {
            const that = this;
            that.loading = true;
            axios.form(urls.training.details, { id: that.training_id }).then(function (response) {
                const json = response.data;
                that.training = json.data;
                that.scheduleOptions = json.schedule_options.data;
                that.countriesOptions = json.countries.data;
                that.loadCaptcha();
                that.loading = false;
            }).catch(function (err) {
                console.log('error : ', err);
            });
        },

        setModel (data) {
            this.payModel.action = data.action;
            this.payModel.name = data.name;
            this.payModel.training_name = data.training_name;
            this.payModel.date = data.date;
            this.payModel.address1 = data.address1;
            this.payModel.address2 = data.address2;
            this.payModel.amount = data.amount;
            this.payModel.city = data.city;
            this.payModel.country = data.country;
            this.payModel.email = data.email;
            this.payModel.firstname = data.firstname;
            this.payModel.furl = data.furl;
            this.payModel.hash_string = data.hash_string;
            this.payModel.hash = data.hashh;
            this.payModel.key = data.key;
            this.payModel.lastname = data.lastname;
            this.payModel.merchant_key = data.merchant_key;
            this.payModel.phone = data.phone;
            this.payModel.productinfo = data.productinfo;
            this.payModel.service_provider = data.service_provider;
            this.payModel.state = data.state;
            this.payModel.surl = data.surl;
            this.payModel.txnid = data.txnid;
            this.payModel.udf1 = data.udf1;
            this.payModel.udf2 = data.udf2;
            this.payModel.udf3 = data.udf3;
            this.payModel.udf4 = data.udf4;
            this.payModel.udf5 = data.udf5;
            this.payModel.zipcode = data.zipcode;
        },

        payToRedirect () {
            this.$refs.paymentConfirm.close();
            this.$refs.paymentRedirect.show();
        }
    }
};
</script>

<style scoped lang="scss">
.bg-blue {
    background-color: #0a0a58;
}

.text-white {
    color: white
}

.grey {
    background-color: #d9d9d9 !important;
}

.c-w-10v {
    width: 23vw;
    @media(min-width: 992px) {
        width: 10vw;
    }
}
</style>
