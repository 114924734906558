<template>
    <div>
        <first></first>
        <div class="text-center text-primary py-7" v-if="dataLoading">
            <loading-animation class="fs-10"/>
            <p>Please wait while data is being loaded...</p>
        </div>
        <div v-else>
            <template v-if="details">
                <second></second>
                <third v-for="(data,i) in details" :key="i" :data="data"></third>
            </template>
            <p v-if="details.length===0 && !dataLoading" class="text-center py-7">
                No data available.
            </p>
        </div>
    </div>
</template>

<script>
import First from '../components/Solutions/first';
import Second from '../components/Solutions/second';
import Third from '../components/Solutions/third';
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'SolutionsPage',
    components: { Third, First, Second },
    data () {
        return {
            dataLoading: false,
            details: []
        };
    },
    mounted () {
        this.loadData();
    },
    methods: {
        async loadData () {
            this.dataLoading = true;
            const response = await axios.get(urls.solutions);
            const json = response.data;
            console.log('career-response', response);
            if (json.error === false) {
                this.details = json.data;
                this.dataLoading = false;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        }
    }
};
</script>

<style scoped>
li {
    color: gray;
    line-height: 2rem;
    /*text-align: justify;*/
}
</style>
