<template>
    <div class="py-5 of-h">
        <div class="butn">
            <img src="../../assets/web/home/Vector2.svg" class="topbtn">
        </div>
        <div class="py-5">
            <!--card 1-->
            <div class="card-1 row  bs-10 mt-lg-5">
                <div class="col-lg-4 mt-lg-3">
                    <img src="../../assets/img/Images/standard-quality-control-concept-m.jpg">
                </div>
                <div class="col-lg-8 mt-lg-2 mt-5 py-2">
                    <h3 class="font-manrope-semi-bold text-primary">Certified Network Defender v2 (CND V2)</h3>
                    <p class="font-manrope-regular">According to Gartner, traditional “prevent and detect” approaches
                        are
                        inadequate.<br> Opportunistic by nature, malicious actors look for the easiest ways to attack
                        the<br>most
                        users and siphon off the maximum gains.</p>
                    <h5 class="font-manrope-semi-bold text-primary">Schedule</h5>
                    <p class="font-manrope-regular">21 Jan 2023 - 29 Jan 2023 (Chennai)</p>
                    <btn text="Learn More" border-radius="0" class="px-4"></btn>
                </div>
            </div>
            <!--card 2-->
            <div class="card-2 row mt-lg-2 bs-10">
                <div class="col-lg-4 mt-lg-3">
                    <img src="../../assets/img/Images/training1.png">
                </div>
                <div class="col-lg-8 mt-lg-2 mt-5 py-2">
                    <h3 class="font-manrope-semi-bold text-primary">Certified Network Defender v2 (CND V2)</h3>
                    <p class="font-manrope-regular">Certified Ethical Hacker CEH v12 will teach you the latest
                        commercial-grade hacking tools, techniques, and methodologies used by hackers and information
                        security professionals to lawfully hack an organization.</p>
                    <h5 class="font-manrope-semi-bold text-primary">Schedule</h5>
                    <p class="font-manrope-regular">21 Jan 2023 - 29 Jan 2023 (Online (Sat - Sun) 2 Weekends)</p>
                    <btn text="Learn More" border-radius="0" class="px-4"></btn>
                </div>
            </div>
            <!--card 3-->
            <div class="card-3 row mt-lg-2 bs-10">
                <div class="col-lg-4 mt-lg-3">
                    <img src="../../assets/img/Images/html-css-collage-concept-with-hacker.jpg">
                </div>
                <div class="col-lg-8 mt-lg-2 mt-5 py-2">
                    <h3 class="font-manrope-semi-bold text-primary">Certified Network Defender v2 (CND V2)</h3>
                    <p class="font-manrope-regular">Certified Ethical Hacker CEH v12 will teach you the latest
                        commercial-grade hacking tools, techniques, and methodologies used by hackers and information
                        security professionals to lawfully hack an organization.</p>
                    <h5 class="font-manrope-semi-bold text-primary">Schedule</h5>
                    <p class="font-manrope-regular">21 Jan 2023 - 29 Jan 2023 (Online (Sat - Sun) 2 Weekends)</p>
                    <btn text="Learn More" border-radius="0" class="px-4"></btn>
                </div>
            </div>
            <div class="butn">
                <img src="../../assets/web/home/Vector1.svg" class="botombtn mt-lg-4 mt-md-5 mt-6">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TrainingCard'
};
</script>

<style scoped lang="scss">
.card-1 {
    background: var(--bg-1);
    transition: all 0.5s ease-in-out;
    transform: scale(0.95);
    &:hover {
        //position:absolute;
        z-index: 799 !important;
        background: var(--bg-2);
        //position: absolute;
        transform: scale(1);
        //height: 15rem;
    }
}

.card-2 {
    background: var(--bg-1);
    transition: all 0.5s ease-in-out;
    transform: scale(0.95);
    &:hover {
        //position:absolute;
        z-index: 799 !important;
        background: var(--bg-2);
        //position: absolute;
        transform: scale(1);
    }
}
.card-3 {
    background: var(--bg-1);
    transition: all 0.5s ease-in-out;
    transform: scale(0.95);
    &:hover {
        //position:absolute;
        z-index: 799 !important;
        background: var(--bg-2);
        //position: absolute;
        transform: scale(1);
    }
}

.bs-10 {
    box-shadow: rgb(167,171,187) 0 5px 10px 0;
}

img {
    width: 100%;
    height: 95%;
}

.topbtn {
    width: 40px;
    margin-left: 505px;
}

.botombtn {
    width: 40px;
    margin-left: 505px;
}

@media (max-width: 765px) {
    img {
        width: 110%;
    }
        .topbtn {
    margin-left: 160px;
}
    .botombtn {
    margin-left: 160px;
}
}

@media (min-width: 768px) and (max-width: 1024px) {
    .topbtn {
    margin-left: 315px;
}
    .botombtn {
    margin-left: 315px;
}
}
</style>
