<template>
    <div>
        <div class="fl-x-cc">
            <div class="col-12 col-lg-10 card bs-1  bg-clr">
                <p class=" pl-lg-5 pl-2 font-manrope-semi-bold text-primary fs-lg-7 mt-lg-5 mt-0 mb-4 mb-lg-5">Enquiry
                    Form</p>
                <validation-observer slim v-slot="{handleSubmit}" ref="formValidator">
                    <form class="px-lg-5 px-2" role="form" @submit.prevent="handleSubmit(SubmitForm)">
                        <div class="row">
                            <div class="col-lg-12 col-12">
                                <validated-input size="m" border-radius="0" label=" Name" :rules="{required:true}"
                                                 v-model="model.name" :disabled="loading"
                                                 class="py-1 text-primary font-manrope-medium c-input-career-c ">
                                </validated-input>
                            </div>

                            <div class="col-md-6 col-12">
                                <validated-input size="m" border-radius="0" label="Email" :rules="{required:true}"
                                                 v-model="model.contact_mail" :disabled="loading" type="email"
                                                 class="py-1 text-primary font-manrope-medium  c-input-career-c "></validated-input>
                            </div>

                            <div class="col-md-6 col-12">
                                <validated-input size="m" border-radius="0" label="Contact No"
                                                 :rules="{required:true, min:10, max:10}"
                                                 v-model="model.contact_no" :disabled="loading" type="number"
                                                 class="py-1 font-manrope-medium text-primary c-input-career-c "></validated-input>
                            </div>
                            <div class="col-lg-12 col-12 mt-2">
                                <validated-input size="m" border-radius="0" label="Enter City"
                                                 :rules="{required: true}" v-model="model.city"
                                                 class="py-1 font-manrope-medium text-primary c-input-career-c ">

                                </validated-input>
                            </div>
                            <div class="col-12 col-lg-12 mt-3 ">
                                <validated-text-area size="m" border-radius="0" :rules="{required:true}"
                                                     label="Details" v-model="model.description" :disabled="loading"
                                                     class="fs-2 pb-2 c-input   font-manrope-medium text-primary place-holder-l-grey text-area-c c-input-career-c"
                                                     name="message"></validated-text-area>
                            </div>
                        </div>
                        <div class="mt-4">
                            <mark class="fl-x-cc grey p-1 mb-0">
                                <div class="row fl-te-c">
                                    <div class="col-10">
                                        <img class="img-fluid" v-if="model.captcha_URL"
                                             :src="model.captcha_URL + '200/'"
                                             alt="Captcha"/>
                                    </div>
                                    <div @click="loadCaptcha" class="col-2">
                                        <loading-animation v-if="captchaLoading" class="text-primary"/>
                                        <i v-else class="fa fa-refresh"/>
                                    </div>
                                </div>
                            </mark>
                        </div>
                        <div class="col-12 col-md-6 p-0 mt-3">
                            <validated-input label="Captcha*" name="Captcha" border-radius="0"
                                             v-model="model.captcha" :rules="{required: true}" :disabled="loading"
                                             class="c-input-primary"></validated-input>
                        </div>
                        <div class="col-12 col-md-6 p-0 mb-3 text-3 text-danger" v-if="captchaError">
                            {{ captchaError }}
                        </div>
                        <div class=" mt-4 mb-4 ">
                            <div class="fl-x-bl">
                                <btn text="Submit" border-radius="0" :loading="loading" loading-text="Submitting..."
                                     class="px-4 font-manrope-regular"></btn>
                            </div>
                        </div>
                    </form>
                </validation-observer>
            </div>
        </div>
    </div>
</template>

<script>
import urls from '../../data/urls';
import axios from 'secure-axios';

export default {
    name: 'EnquiryFormPage',
    data () {
        return {
            model: {
                name: '',
                contact_mail: '',
                contact_no: '',
                city: '',
                description: '',
                captcha_URL: '',
                captcha_key: '',
                captcha: ''
            },
            loading: false,
            captchaError: '',
            captchaLoading: false,
            training_id: this.$route.params.id,
            enquiryUrl: urls.training.enquiry
        };
    },
    mounted () {
        this.loadCaptcha();
    },
    methods: {
        async loadCaptcha () {
            this.captchaLoading = true;
            const response = await axios.get(urls.getCaptcha);
            const json = response.data;
            if (json.error === false) {
                this.model.captcha_key = json.key;
                this.model.captcha_URL = json.image_url;
                this.model.captcha = '';
                setTimeout(() => {
                    this.captchaLoading = false;
                }, 1000);
            } else {
                this.$notify('Could not load captcha, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            setTimeout(() => {
                this.captchaLoading = false;
            }, 1000);
        },
        async SubmitForm () {
            this.loading = true;
            this.captchaFormError('', true);
            const formValidator = this.$refs.formValidator;
            const response = await axios.form(this.enquiryUrl, this.model);
            const json = response.data;
            if (json.error === false) {
                this.loading = false;
                this.$notify('Submitted Successfully', '', {
                    type: 'success'
                });
                this.$router.go();
            }
            if (json.error === true) {
                formValidator.setErrors(json.errors);
            }
            if (json.captcha === false) {
                this.loading = false;
                this.captchaFormError(json, false);
            }
            this.loading = false;
        },
        captchaFormError (json, clear) {
            if (clear) {
                this.captchaError = '';
            } else {
                this.captchaError = 'Invalid Captcha';
                this.model.captcha_URL = json.image_url;
                this.model.captcha_key = json.key;
            }
        },
        formSuccess () {
            this.$notify('Submitted Successfully', 'Success', {
                type: 'success'
            });
            this.$router.go();
        },
        formError () {
            this.$notify('Could not submit the data, please try again later', 'Error', {
                type: 'danger'
            });
            this.$router.go();
        }
    }
};
</script>

<style scoped>
.bg-clr {
    /*background-color: rgba(235, 237, 254, 255);*/
}
</style>
