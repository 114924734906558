<template>
    <div>
        <div class="container pt-0 pt-lg-6">
            <div class="row">
                <div class="col-12">
                    <section-heading size="lg" title="MANAGEMENT"></section-heading>
                </div>
                <div v-if="details.length===0" class="col-12 mt-5">
                    <div class="section-img mx-lg-10 mx-3 my-3" :style="setBgImage(img)">
                    </div>
                    <div class="mt-lg-5 px-lg-8 px-3">
                        <p class="text-sm-justify fs-lg-1 font-poppins-regular line-h-2 text-1">
                            We have experienced & qualified Information Security Management professionals on our board.
                            The professionals bring in with them more than two decades of rich industry experience. The
                            professionals have proven track record of technical, tactical, managerial skills and have
                            served senior strategical role in various multinational, government and banking industry.
                        </p>
                    </div>
                </div>
                <div v-else class="col-12 mt-4 mt-lg-5">
                    <div class="section-img mx-lg-10 mx-3 my-3" :style="setBgImage(details[0].image)">
                    </div>
                    <div class="mt-lg-5 px-lg-8 px-3">
                        <p class="text-sm-justify fs-lg-1 font-poppins-regular line-h-2 text-1">
                            {{ details[0].description }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import img1 from '../../assets/web/home/business-executive-applauding-during-video-conference.jpg';

export default {
    name: 'ManagementSection',
    props: {
        details: {
            type: Array, default: null
        }
    },
    data () {
        return {
            img: img1
        };
    },
    methods: {
        setBgImage (image) {
            return { backgroundImage: 'url(' + image + ')' };
        }
    }
};
</script>

<style scoped>
.section-img {
    display: flex;
    justify-content: center;
    background-position: center;
    background-size: cover;
    height: 17rem;
}

</style>
