<template>
    <div class="row">
        <div class="col-12 mb-0">
            <h3 class="text-primary fs-lg-4 font-manrope-medium mb-4">Recent blog post</h3>
        </div>
        <div class="col-12 col-md-6">
            <recent-blog-post1 :data="details[0]" size="lg"/>
        </div>
        <div class="col-12 col-md-6 mt-4 mt-lg-0">
            <blog-last-mile v-for="(item, i) in details.slice(1,5)" :key="i" :class="{'mt-lg-4': i>0}" class="mt-4 mt-lg-0"
                            :data="item"></blog-last-mile>
        </div>

    </div>
</template>

<script>
import RecentBlogPost1 from '../../../components/home-components/RecebtBlogPost1';
import BlogLastMile from '../../../components/home-components/BlogLastMile';

export default {
    name: 'RecentBlogsSection',
    components: { BlogLastMile, RecentBlogPost1 },
    props: {
        details: {
            type: Array,
            default: null
        }
    }
};
</script>

<style scoped lang="scss">
</style>
