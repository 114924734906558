<template>
    <div>
        <div class="container py-0">
            <div class="row">
                <div class="col-12">
                    <section-heading size="lg" title="EDU TECH SERVICES"
                                     info="We provide individual training for Certifications to aspirants who are keen in taking up Cyber Security and allied services as their career choice."></section-heading>
                </div>
                <div v-if="details.length!==0" class="col-12 d-sm-none d-md-none" :class="{'min-h-100': details.length >2}">
                    <training-swiper-vertical :details="details"></training-swiper-vertical>
                </div>
                <div class="col-12 d-lg-none p-0">
                    <training-swiper-small-screens v-if="details.length!==0"
                                                   :details="details"></training-swiper-small-screens>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import TrainingSwiperVertical from '../../../components/home-components/TrainingSwiperVertical';
import TrainingSwiperSmallScreens from '../../../components/home-components/TrainingSwiperSmallScreens';
import axios from 'secure-axios';
import urls from '../../../data/urls';

export default {
    name: 'TrainingSection',
    components: { TrainingSwiperSmallScreens, TrainingSwiperVertical },
    props: {
        details: {
            type: Array, default: null
        }
    },
    // data () {
    //     return {
    //         details: null
    //     };
    // },
    mounted () {
        // this.loadData();
    },
    methods: {
        async loadData () {
            // this.dataLoading = true;
            const response = await axios.get(urls.training.details);
            console.log('response-data', response.data);
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            // this.dataLoading = false;
        }
    }
};
</script>

<style scoped lang="scss">
.min-h-100 {
    @media (min-width: 992px) {
        min-height: 100vh !important;
    }
}
</style>
