<template>
    <div class="blog-pagination mt-5">
        <div v-if="currentPage !== 1" @click="setPage(currentPage - 1)" class="prev-btn b-1">
            <img src="../../assets/web/Icons/blog/pagination-prev-icon.svg" alt="">
        </div>
        <div v-else class="prev-btn b-1 bg-1">
            <img src="../../assets/web/Icons/blog/pagination-prev-icon.svg" alt="">
        </div>
        <div class="active">
            <span>{{ currentPage }}</span>
        </div>
        <div v-if="currentPage !== lastPage" @click="setPage(currentPage + 1)" class="next-btn">
            <img src="../../assets/web/Icons/blog/pagination-next-icon.svg" alt="">
        </div>
        <div v-else class="next-btn bg-1">
            <img src="../../assets/web/Icons/blog/pagination-next-icon.svg" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: 'BlogPagination',
    props: {
        from: {
            type: Number,
            default: 0
        },
        to: {
            type: Number,
            default: 0
        },
        total: {
            type: Number,
            default: 0
        },
        currentPage: {
            type: Number,
            default: 0
        },
        lastPage: {
            type: Number,
            default: 0
        },
        perPage: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {};
    },
    methods: {
        setPage (page) {
            this.$emit('refresh', page);
        }
    }
};
</script>

<style scoped lang="scss">
.blog-pagination {
    position: relative;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    //width:50%;
    width: 24rem;

    div {
        transition: all 0.5s ease-in-out;
        padding: 0.425rem 0.95rem;
        text-align: center;
        border: var(--border-1) solid var(--border-color);
        font-family: inter-medium;
        cursor: pointer;

        &.active {
            background: #F9FAFB;
        }
    }

    .next-btn {
        border-radius: 0px 8px 8px 0px;
    }

    .prev-btn {
        border-radius: 8px 0 0 8px;
    }

    &::before {
        content: '';
        position: absolute;
        width: 120%;
        top: -0.75rem;
        left: 50%;
        transform: translateX(-50%);
        height: 2px;
        background: rgba(128, 128, 128, 0.09);
    }
}

</style>
