<template>
    <div class="pt-6 pb-4 section-min-h">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <section-heading size="lg" title="WHAT OUR CLIENTS SAY!"
                                     class="mb-5 font-manrope-semi-bold"></section-heading>
                </div>
                <div class="col-12 mt-5">
                    <!--                    <clients-say-card></clients-say-card>-->
                    <swiper class="swiper c-swiper-1 swiper-primary pt-2 pb-5" :options="swiperOption">
                        <template v-if="details === null">
                            <swiper-slide v-for="i in 3" :key="i">
                                <div class="fl-x fl-j-c">
                                    <clients-say-card></clients-say-card>
                                </div>
                            </swiper-slide>
                        </template>
                        <template v-else>
                            <swiper-slide v-for="(data,i) in details" :key="i">
                                <div class="fl-x fl-j-c">
                                    <clients-say-card class="mx-3 mx-md-0" :data="data"></clients-say-card>
                                </div>
                            </swiper-slide>
                        </template>
                        <div class="swiper-pagination mt-3" slot="pagination"></div>
                    </swiper>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ClientsSayCard from '../../../components/home-components/ClientsSayCard';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
    name: 'WhatClientsSaySection',
    components: { ClientsSayCard, Swiper, SwiperSlide },
    props: {
        details: {
            type: Array, default: null
        }
    },
    data () {
        return {
            swiperOption: {
                autoHeight: true,
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                mousewheel: false,
                parallax: true,
                centeredSlides: false,
                // autoplay: true,
                autoplay: { delay: 8000, disableOnInteraction: false },
                navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
                breakpoints: {
                    1200: {
                        speed: 1500,
                        slidesPerView: 1
                    },
                    1024: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 1000
                    },
                    768: {
                        slidesPerView: 1,
                        spaceBetween: 0,
                        speed: 800
                    },
                    640: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    320: { slidesPerView: 1, spaceBetween: 0, speed: 800 }
                }

            }

        };
    }
};
</script>

<style scoped lang="scss">
//.section-min-h {
//  @media(min-width: 992px) {
//    min-height: 80vh !important;
//  }
//}
.swiper-pagination {
    top: 0;
    transform: translateX(-12%);
}

.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    //background-color: rgba(0, 0, 0, 0.4);
    //width: 4rem;
    bottom: 1.25rem !important;
    left: 0 !important;
    @media (min-width: 992px) {
        bottom: 2rem !important;
    }

    .swiper-pagination-bullet {
        border: 1px solid black !important;
        //background: white !important;
        left: 0 !important;
    }

    .swiper-pagination-bullet-active {
        background-color: gray !important;
    }

}

.swiper-pagination-bullet-active {
    background: gray !important;
}

</style>
