<template>
    <div v-if="data" class="row cursor-pointer" @click="openBlogArticlePage">
        <div class="col-lg-6 p-0">
            <img alt="image" src="../../assets/img/Images/standard-quality-control-concept-m.jpg" class="img-fluid"
                 v-if="data === null">
            <img alt="image" class="img-fluid" :src="data.image" v-else>
        </div>
        <div class="col-lg-6">
            <div class="fl-x mb-2">
                <p v-if="data.author_name" class="font-manrope-medium fs--1 text-2 mb-1 mr-1">{{ data.author_name }}</p>
<!--                <span class="ml-2"> <img src="../../assets/img/Images/blog-article/line-img.svg" alt=""></span>-->
                <li class="font-manrope-medium fs--1 text-2 ml-2">{{ data.create_date }}</li>
            </div>
            <template>
                <div class="h-6re">
                    <h2 v-if="data.title" class=" font-manrope-medium text-primary fs-lg-2 bb-black fl-j-c fs-lg-1">{{
                            data.title
                        }}</h2>
                    <p v-if="data.title_description" class="font-manrope-medium text-2 fs-lg--1 fl-j-c mb-2">
                        {{ data.title_description }}
                    </p>
                </div>

            </template>

            <div class="fl-x mt-41">
                <p v-if="data.category" class="p-btn  font-manrope-medium mr-2">{{ data.category }}</p>
<!--                <div class="">-->
<!--                    <btn design="basic-a" border-radius="4" size="sm" class="font-manrope-medium ">download</btn>-->
<!--                </div>-->
                <div>
                    <btn design="basic-a" border-radius="4" size="sm" class="font-manrope-medium px-4">view</btn>
                </div>
            </div>

        </div>
    </div>
</template>

<script>
export default {
    name: 'BlogLastMile',
    props: {
        data: {
            type: Object,
            default: null
        }
    },
    methods: {
        openBlogArticlePage () {
            this.$router.push('/blog-article-article/' + this.data.id + '/details/');
        }
    }

};
</script>

<style scoped>
img {
    width: 100%;
    height: 100%;
}

.mt-41 {
    margin-top: 2rem !important;
}

.h-6re {
    height: 6rem;
}

.p-btn {
    border: 1px solid black;
    border-radius: 16px;
    font-size: 12px;
    color: #3D3F40;
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 4px;
    padding-bottom: 5px;
}

@media (max-width: 765px) {
    img {
        /*width: 80%;*/
    }
}
</style>
