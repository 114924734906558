<template>
    <div class="py-5">
        <div class="container">
            <section-heading size="lg" title="SERVICES"
                             info="Cyberfort provides Strategic & Tactical Cybersecurity Threat Management professional
                              services to our clients to manage their growing Cyber Risks based on Cost-Benefits-Risks.
                               Further, Cyberfort also addresses the day-to-day Cybersecurity Operational Management needs of
                                our clients with our experienced & skilled Cybersecurity resources. "></section-heading>
            <!--            <p class="font-manrope-medium fs-lg-1 text-center fs&#45;&#45;1 fl-x-cc text-center pb-5 bb-black px-lg-9 px-1 mt-5">Lorem ipsum-->
            <!--                dolor sit amet,consectetur adipiscing elit,sed do eiusmod tempor incididunt ut labore et dolore-->
            <!--                magna aliqua.Ut enim ad minir adipisicing elit. Aut, omnis.</p>-->
        </div>
    </div>
</template>

<script>
export default {
    name: 'ServicesPage',
    props: {
        details: {
            type: Object, default: null
        }
    },
    components: {}
};
</script>

<style scoped>
    .head1 {
        text-align: center;
        color: midnightblue;
    }

    p {
        color: #323232;
    }

    @media (max-width: 765px) {

    }
</style>
