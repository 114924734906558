<template>
    <div>
        <div class="container">
            <div class="text-center text-primary py-7" v-if="dataLoading">
                <loading-animation class="fs-10"/>
                <p>Please wait while data is being loaded...</p>
            </div>
            <div v-else>
                <div class="heading font-manrope-semi-bold mt-5">
                    <section-heading size="xlg" title="CONTACT US"></section-heading>
                    <div class="heading font-manrope-semi-bold  mt-6">
                        <h2 style="text-align:center;" class="fs-lg-8">We'd love to hear from you</h2>
                    </div>
                </div>
                <detailspage :details="details"></detailspage>
                <div>
                    <formpage></formpage>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import Detailspage from '../components/ContactPage/detailspage';
import Formpage from '../components/ContactPage/formpage';
import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'ContactPage',
    // eslint-disable-next-line vue/no-unused-components
    components: { Formpage, Detailspage },
    data () {
        return {
            details: null,
            dataLoading: false
        };
    },
    mounted () {
        this.loadData();
    },
    methods: {
        async loadData () {
            this.dataLoading = true;
            const response = await axios.get(urls.contactUsPage);
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
            this.dataLoading = false;
        }
    }
};
</script>

<style scoped>

</style>
