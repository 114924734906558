<template>
    <div class="py-6">
        <div class="container">
            <section-heading size="lg" title="SOLUTION & CONSULTANCY SERVICE"></section-heading>
            <p class="para font-manrope-medium fs-lg-1 fs--1 fl-x-cc text-center pb-5 bb-black py-4">In order to
                support the organizations in their Digital Transformation journey and adoption of emergency <br>technologies
                like AI,ML,IOT,BlockChain,BigData ina secure manner,we are providing solution and <br>consultancy
                services ina a cost effective with a high value to the organizations: </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'SolutionsConsultancy',
    components: { }
};
</script>

<style scoped>

</style>
