<template>
    <div class="row">
        <div class="col-12 mb-0">
            <h3 class="text-primary fs-lg-4 font-manrope-medium mb-4">All blog posts</h3>
        </div>
        <template>
            <div class="col-lg-4 col-md-6 mt-4 mt-lg-0" v-for="(item, i) in details" :key="i">
                <recent-blog-post1 :data="item"></recent-blog-post1>
            </div>
        </template>

        <div class="col-12 mt-5 text-center">
            <btn @click="$router.push('/all-blogs/')" text="View All" size="lg" border-radius="0"
                 class="px-5 fs-lg-1 font-poppins-regular"></btn>
        </div>
    </div>
</template>

<script>
import RecentBlogPost1 from '../../../components/home-components/RecebtBlogPost1';

export default {
    name: 'AllBlogPosts',
    components: { RecentBlogPost1 },
    props: {
        details: {
            type: Array,
            default: null
        }
    }
};
</script>

<style scoped>

</style>
