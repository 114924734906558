<template>
    <div>
        <div class="container pt-lg-5 pt-6">
            <div class="row">
                <div class="col-12">
                    <section-heading :show-bottom-line="false">
                        <template #custom>
                            <div class="fs-lg-5 mb--2 mt-n5 mt-md-n4 mt-lg-0">
                                <h4 class="text-primary fs-lg-9 font-manrope-medium">CYBER<span
                                    class="text-danger fs-lg-9">FORT</span>
                                    <span class="text-6 fs-lg-9">&nbsp;offers Cyber Security management</span><br><span
                                        class="text-6 fs-lg-9">professional
                                services</span></h4>
                            </div>
                        </template>
                    </section-heading>
                </div>
                <div class="col-12">
                    <div class="pos-r">
                        <swiper v-if="details.length===0" style="height: 60vh;" class="swiper swiper-primary pb-lg-5"
                                :options="swiperOption">
                            <swiper-slide v-for="i in 3" :key="i">
                                <professional-services-card></professional-services-card>
                            </swiper-slide>
                        </swiper>
                        <swiper v-else class="swiper swiper-primary pt-lg-6 pt-lg-4 pb-lg-6 pb-2 pt-3 h-80v-res" style=""
                                :options="swiperOption" :class="{'slides-j-c': details.length<3}">
                            <swiper-slide class="" v-for="(service, index) in details" :key="index">
                                <professional-services-card :details="service"></professional-services-card>
                            </swiper-slide>
                        </swiper>
                        <div class="swiper-pagination swiper-pagination-white" slot="pagination"></div>

                        <div class="swiper-btn swiper-button-prev pr-md-4 pl-md-3  px-2 fs--1"
                             slot="button-prev" v-if="details.length>3">
                            <img src="../../assets/web/Icons/arrow-left.svg" class="d-lg-none" alt="">
                        </div>
                        <div class="swiper-btn swiper-button-next px-md-4 px-2" slot="button-next" v-if="details.length>3">
                            <img src="../../assets/web/Icons/arrow-right.svg" class="d-lg-none" alt="">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ProfessionalServicesCard from '../../components/home-components/ProfessionalServicesCard';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';

export default {
    name: 'ProfessionalServicesSection',
    components: { ProfessionalServicesCard, Swiper, SwiperSlide },
    props: {
        details: {
            type: Array, default: null
        }
    },
    data () {
        return {
            swiperOption: {
                autoHeight: true,
                slidesPerView: 3,
                spaceBetween: 0,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                mousewheel: false,
                parallax: true,
                centeredSlides: false,
                // autoplay: true,
                autoplay: { delay: 8000, disableOnInteraction: false },
                navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
                breakpoints: {
                    1200: {
                        speed: 1500,
                        slidesPerView: 3
                    },
                    1024: {
                        slidesPerView: 3,
                        spaceBetween: 0,
                        speed: 1000
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 0,
                        speed: 800
                    },
                    640: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    320: { slidesPerView: 1, spaceBetween: 0, speed: 800 }
                }

            },
            items: []
        };
    }

};
</script>

<style scoped lang="scss">
    main {
        //background: #f2f2f2;
    }

    .mb--2 {
        margin-bottom: -2.9rem !important;
    }

    .h-80v-res {
        height: 83vh !important;
        /*height: 85vh !important;*/
        /*@media (max-width: 767px) {*/
        /*    height: unset !important;*/
        /*}*/
        @media (min-width: 992px) {
            height: 100vh !important;
        }
        @media (min-width: 768px) and (max-width: 1023px) {
            height: 52vh !important;
        }
        @media (min-width: 1800px) {
            height: 47vh !important;
        }
    }

    .swiper-btn {
        position: absolute;
        z-index: 799 !important;
        color: rgba(128, 128, 128, 0.7) !important;
        //border: 1px solid rgba(128, 128, 128, 0.9);
        //background-color: var(--color-white);
        //padding: var(--spacer-2);
        font-size: 0.9rem !important;
        color: var(--color-primary) !important;
        top: 50% !important;
        transform: translateY(-50%) !important;

        &.swiper-button-prev {
            //left: -3.5rem;
            left: -0.9rem;

            &::after {
                display: none;
            }

            @media (min-width: 992px) {
                left: 0.2rem;
                &::after {
                    display: block;
                }
            }
        }

        &.swiper-button-next {
            //right: -2.5rem;
            right: -0.8rem;

            &::after {
                display: none;
            }

            @media (min-width: 992px) {
                right: 0.8rem;
                &::after {
                    display: block;
                }
            }
        }
    }

    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
        bottom: 10px;
        left: 50%;
        transform: translate(-50%, 0);
        width: unset;
        padding: 3px 25px;
        background: #2929293b;
        border-radius: 15px;
    }

    .swiper {
        //height: 110vh !important;
        .swiper-slide {
            height: 90vh !important;
        }
    }
</style>
