<template>
    <div>
        <div class="container py-5">
            <div class="row">
                <div class="col-12">
                    <img src="../../assets/web/placeholder-imgs/services-page-top-image.png" class="img-fluid" alt="">
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                    <img src="../../assets/web/placeholder-imgs/about/OBJECTS.png" class="img-fluid" alt="">
                </div>
                <div class="col-12">
                    <img src="../../assets/web/placeholder-imgs/home/professional-services-card.png" class="img-fluid" alt="">
                </div>
                <div class="col-12">
                    <img src="../../assets/web/placeholder-imgs/home/service-1.png" class="img-fluid" alt="">
                    <img src="../../assets/web/placeholder-imgs/home/service-2.png" class="img-fluid" alt="">
                    <img src="../../assets/web/placeholder-imgs/home/gallery-image-item.png" class="img-fluid" alt="">
                    <img src="../../assets/web/placeholder-imgs/gallery/gallery-innerpage-img.png" class="img-fluid" alt="">
                    <img src="../../assets/web/placeholder-imgs/gallery/gallery-innerpage-img.png" class="img-fluid" alt="">
                </div>
            </div>
            <section-heading title="Components"></section-heading>
            <div class="mt-5">
                <h3 class="mt-4 mb-4">Section Headings</h3>
                <!--                <h4 class="mt-4">Bottom line on center</h4>-->
                <section-heading title="Heading Bottom Line Center"></section-heading>
                <!--                <h4 class="mt-5">Bottom line on left</h4>-->
                <br/><br/>
                <section-heading title="Heading Bottom Tag align Left" bottom-tag-align="left"></section-heading>
                <!--                <h4 class="mt-5">Heading on Left(Sub headings)</h4>-->
                <br><br>
                <section-heading title="Heading Left Align" align="left"
                                 bottom-tag-align="left"></section-heading>
                <br><br>
                <!--                <h4>Heading Upper case</h4>-->
                <section-heading size="lg" title="Heading Uppercase and Large(Main Heading)" :text-uppercase="true"
                                 align="center">

                </section-heading>
                <br><br>
                <section-heading title="Heading Without Bottom Line" :show-bottom-line="false"></section-heading>
            </div>
            <div>
                <h3 class="mb-4">Button</h3>
                <btn text="Square border" border-radius="0" class="px-4"></btn>
            </div>
            <div class="row">
                <div class="col-12 col-lg-4">
                    <Professional-ServicesCard></Professional-ServicesCard>
                </div>
            </div>
            <div class="">
                <div class="">
                    <ServicesCard></ServicesCard>
                </div>
                <div class="">
                    <div class="">
                        <TrainingCard></TrainingCard>
                    </div>
                    <div class="">
                        <div class="">
                            <SolutionsConsultancy></SolutionsConsultancy>
                            <div class="">
                                <div class="">
                                    <SolutionsImage></SolutionsImage>
                                </div>
                                <div>
                                    <recent-blog-post1></recent-blog-post1>
                                </div>
                                <div>
                                    <BlogLastMile></BlogLastMile>
                                </div>
                                 <div>
                                    <ClientSay></ClientSay>
                                </div>
                                 <div>
                                    <TrainingCertificationForm></TrainingCertificationForm>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import SectionHeading from '@components/ui/SectionHeading';
import ProfessionalServicesCard from '@components/home-components/ProfessionalServicesCard';
import ServicesCard from '@components/home-components/ServicesCard';
import RecentBlogPost1 from '@components/home-components/RecebtBlogPost1';
import SolutionsConsultancy from '@components/home-components/SolutionsConsultancy';
import SolutionsImage from '@components/home-components/SolutionsImage';
import TrainingCard from '@components/home-components/TrainingCard';
import BlogLastMile from '@components/home-components/BlogLastMile';
import ClientSay from '@components/home-components/ClientSay';
import TrainingCertificationForm from '@components/home-components/TrainingCertificationForm';

export default {
    name: 'ComponentsPage',
    components: {
        TrainingCertificationForm,
        ClientSay,
        BlogLastMile,
        TrainingCard,
        SolutionsImage,
        SolutionsConsultancy,
        RecentBlogPost1,
        ServicesCard,
        SectionHeading,
        ProfessionalServicesCard
    }
};
</script>

<style scoped>
h1, h2, h3, h4, h5, h6 {
    text-align: center;
}
</style>
