<template>
    <div id="services">
        <div class="container pb-4 pt-4 pt-lg-0">
            <section-heading size="lg" title="SERVICES" class="mb-lg-7 mb-5"></section-heading>
            <div v-if="details.length===0" class="px-4 px-md-0 px-lg-0">
                <div class="card-1 row mt-md-6">
                    <div class=" col-md-6 mt-2 font-manrope-regular text-primary ">
                        <h3 class="font-manrope-bold">Infrastructure Security Assesment</h3>
                        <p class="p1 mt-3 pr-4">At Cyberfort we can co-work with you to meet such regulatory requirement
                            and
                            ensuring systems security is maintained, and controls are functioning as intended.
                        </p>
                        <btn text="Learn More" design="" size="lg" border-radius="0"
                             class="px-lg-5 fs-lg-1  font-poppins-regular"></btn>
                    </div>
                    <div class="col-md-6">
                        <div class="px-lg-6 mt-md-4 mt-lg-0">
                            <img src="../../assets/img/Images/Signing.jpg" class="img-fluid img-shadow round-1">
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <div v-for="(i, index) in details" :key="index">
                    <div v-if="index % 2 !== 0 && index!==0" class="px-4 px-md-0 px-lg-0 mt-5 mt-lg-0">
                        <div class="card-1 row mt-md-6">
                            <div class=" col-md-6 mt-lg-2 mt-4 font-manrope-regular text-primary ">
                                <h3 class="font-manrope-bold fs-lg-5">{{ i.title }}</h3>
                                <p class="p1 mt-3 fs-lg-1 font-poppins-regular pr-md-0 text-justify">{{ i.title_description }}</p>
                                <btn @click="$router.push('/services/'+ i.id )" text="Learn More" design="basic" size="lg" border-radius="0"
                                     class="px-lg-5 fs-lg-1 font-poppins-regular grey"></btn>
                            </div>
                            <div class="col-md-6">
                                <div class="pl-lg-3 mt-md-6 mt-lg-0 pt-lg-4 fl-x fl-j-s">
                                    <img :src="i.image" class="section-img img-shadow round-1">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else class="px-4 px-md-0 px-lg-0">
                        <div class="row mt-lg-6 mt-6">
                            <div class="col-md-6">
                                <div class="pr-lg-5 pb-lg-2 mt-md-6 pb-lg-4 mt-lg-0 pr-lg-5 pt-lg-4 fl-x fl-j-s">
                                    <img :src="i.image" class="section-img img-shadow round-1">
                                </div>
                            </div>
                            <div class="col-md-6 mt-2 font-manrope-regular text-primary ">
                                <div class="px-lg-2 mt-3 mt-md-3 mt-lg-0">
                                    <h3 class="font-manrope-bold fs-lg-5">{{ i.title }}</h3>
                                    <p class=" parag2 font-poppins-regular fs-lg-1 text-justify  mt-3">{{ i.title_description }}</p>
                                    <btn @click="$router.push('/services/'+ i.id )" text="Learn More" size="lg" border-radius="0"
                                         class=" px-lg-5 fs-lg-1 mb-lg-1  font-poppins-medium"></btn>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'ServicesSection',
    props: {
        details: {
            type: Array, default: null
        }
    },
    components: {}
};
</script>

<style scoped lang="scss">
    //p {
    //    width: 318px;
    //}

    .header {
        text-align: center;
    }

    .serviceimages {
        width: 100%;
        border-radius: 5px;
        box-shadow: 0px 0px 56px 32px rgba(0, 53, 188, 0.13);
    }

    .section-img {
        width: 100%;
        @media (min-width: 992px) {
            width: 85%;
            height: auto;
        }
    }

    h3 {
        color: #0E196D;
    }

    p {
        color: #676767;
    }

    .parag2 {
        color: #676767;
    }

    .parag3 {
        color: #676767;
    }

    .img-shadow {
        box-shadow: 0px 0px 56px 32px rgba(0, 53, 188, 0.13);
    }

    .serviceimages-2 {
        width: 76%;
        height: 80%;
        border-radius: 5px;
        box-shadow: 0px 0px 56px 32px rgba(0, 53, 188, 0.13);
    }

    .grey {
        background: #D0D0D0;
        border: #D0D0D0;
        color: black !important;

        &:hover {
            background: #E5E4E2;
            border: #D0D0D0;
            color: black !important;
        }
    }

    /*:hover {*/
    /*    color: var(--color-white) !important;*/
    /*}*/
    @media (max-width: 765px) {
        .card-1 {
            display: flex;
            flex-direction: column-reverse;
        }

        .serviceimages-2 {
            width: 100%;
            height: 86%;
        }

        .card-3 {
            display: flex;
            flex-direction: column-reverse;
        }
        .grey {
            background: #D0D0D0;
            border: #D0D0D0;
            color: black !important;

            &:hover {
                background: #E5E4E2;
                border: #D0D0D0;
                color: black !important;
            }
        }

    }

    @media (min-width: 768px) and (max-width: 1024px) {
        /*.card-1 {*/
        /*    text-align: center;*/
        /*}*/
        .p1 {
            margin-left: 0px;
        }

        .px-4 {
            margin-bottom: 25px;
        }

        .card-2 {
            display: flex;
            flex-direction: column-reverse;
            text-align: center;
        }

        .serviceimages-2 {
            width: 100%;
        }

        /*.parag2 {*/
        /*    margin-left: 165px;*/
        /*}*/
        .grey {
            margin-bottom: 25px;
        }

        /*.card-3 {*/
        /*    text-align: center;*/
        /*}*/
        .parag3 {
            margin-left: 0px;
        }
        .grey {
            background: #D0D0D0;
            border: #D0D0D0;
            color: black !important;

            &:hover {
                background: #E5E4E2;
                border: #D0D0D0;
                color: black !important;
            }
        }
    }
</style>
