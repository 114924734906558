<template>
    <div class="cardss py-3 bs-10">
        <div class="col-lg-2 col-md-3">
            <img v-if="data === null" src="../../assets/web/home/rectangle.svg" class="mt-lg-4 mt-5 client">
            <img v-else :src="data.profile_img" class="mt-lg-4 mt-5 client">
        </div>
        <div class="col-lg-10 col-md-9">
            <div class="ml-lg-5 mt-lg-5 mt-3 font-poppins-regular text-7 fs-lg-1 clientpara m-lg-3 mt-md-5">

                <p v-if="data === null" class="mb-1 text-black">
                    <span>
                        <img src="../../assets/web/Icons/quote-open.svg"
                                             class="mr-lg-1">
                    </span>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit ut aliquam, purus sit amet luctus
                    venenatis,
                    lectus magna fringilla urna, porttitor rhoncus dolor purus non enim praesent elementum facilisis
                    leo, vel purus
                    <span><img src="../../assets/web/Icons/quote-close.svg" class="space"></span>
                </p>
                <p class="font-poppins-regular mb-0 text-7 text-justify text-black" v-else>
                   <span>
                        <img src="../../assets/web/Icons/quote-open.svg"
                                             class="">
                    </span>
                    {{ data.comment.slice(0,212) }}
                    <span><img src="../../assets/web/Icons/quote-close.svg" class="space"></span>
                </p>
            </div>
            <h4 v-if="data === null" class="ml-lg-5 font-manrope-bold text-primary fs-lg-2">Sanjay Krishna</h4>
            <h4 v-else class="ml-lg-5 fs-lg-2 mt-md-3 mt-3 font-manrope-bold text-primary ">{{ data.name }}</h4>
        </div>
    </div>

</template>

<script>
export default {
    name: 'ClientsSayCard',
    props: {
        data: {
            type: Object,
            default: null
        }
    }
};
</script>

<style scoped>
    .cardss {
        width: 680px;
        height: auto;
        min-height: 15rem;
        border: 1px;
        border-radius: 12px;
        display: flex;
    }

    img {
        border-radius: 12px;
    }

    .client {
        width: 140px;
        margin-top: 30px;
    }

    /*.clientpara {*/
    /*    font-size: smaller;*/
    /*}*/

    .bs-10 {
        box-shadow: rgb(167 171 187) 0 1px 10px 0;
    }

    .space {
        margin-left: 240px;
    }

    p {
        color: #050505;
    }

    @media (max-width: 765px) {
        .cardss {
            width: 354px;
            height: auto;
            display: flex;
            flex-direction: column;
        }

        .space {
            margin-left: 32px;
        }

        .client {
            width: 180px;
            margin-left: 70px;
        }

        .clientpara {
            line-break: anywhere;
        }
    }

    @media (min-width: 768px) and (max-width: 1024px) {
        .cardss {
            width: 680px;
        }

        .space {
            margin-left: 40px;
        }
    }
</style>
