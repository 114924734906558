<template>
    <div class="backimg pos-r">
        <div class="container h-100p">
            <div class="fl-x-cc h-100p py-lg-7">
                <div class="text-center font-manrope-semi-bold">
                    <h3 class="fs-lg-7">Strengthening Your Data Security</h3>
                    <p class="mb-0 fs--1 fs-lg-2">Fortifying Digital Security</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'second'
};
</script>

<style scoped lang="scss">
    .backimg {
        //Mobile Screens
        height: 6rem;
        width: 100%;

        &.lg {
            @media (min-width: 768px) and (max-width: 1024px) {
                //Tabs and Medium
                height: 10rem;
            }
            @media (min-width: 1025px) {
                //Large Screen
                height: 25rem;
            }
        }

        //background-repeat: no-repeat;
        background-image: url("../../assets/img/Images/Abstract.png");
        background-size: cover;
        @media (min-width: 768px) and (max-width: 1024px) {
            //Tabs and Medium
            height: 12rem;
        }
        @media (min-width: 992px) {
            //Large Screen
            height: 17rem;
        }
    }

    h3 {
        color: white;
    }

    p {
        color: white;
    }
</style>
