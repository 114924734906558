<template>
    <div class="py-5">
        <div class="container font-manrope-semi-bold">
            <section-heading size="xlg" title="PARTNERS" class="mb-5"></section-heading>
            <div class="fl-x-cc">
                <div class="mt-lg-5">
                    <img v-if="!banner" alt="banner-image" src="../Partners/partners/assets/Images/Certificate.png"
                         class="img-fluid ">
                    <img v-else alt="banner-image" :src="banner" class="img-fluid partner-heading-img" style="">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'certificate',
    props: {
        banner: {
            type: String
        }
    },
    components: {}
};
</script>

<style scoped lang="scss">
    hr.new2 {
        border-top: 1px solid darkblue;
        width: 95px;
        margin-left: 505px;
    }

    .partner-heading-img {
        /*width: 620px;*/
        /*height: 480px;*/
        @media (min-width: 992px) {
            height: 25rem;
        }
        @media (min-width: 768px) and (max-width: 1024px) {
            height: 20rem;
        }
    }

    .heading {
        text-align: center;
        color: midnightblue;
    }

    .imag {
        justify-content: center;
        margin-left: 225px;
    }

    @media (max-width: 768px) {
        .imag {
            background: none;
            box-shadow: none;
            width: 0px;
            height: 0px;
            justify-content: center;
            margin-left: 0px;
        }

        .certificate {
            width: 25rem;
            height: 20rem;
        }

        hr.new2 {
            border-top: 1px solid darkblue;
            width: 95px;
            margin-left: 140px;
        }
    }

    /*@media (min-width: 768px) and (max-width: 1024px) {*/
    /*    .certificate {*/
    /*        width: 568px;*/
    /*        height: 410px;*/
    /*        margin-left: 70px;*/
    /*    }*/
    /*}*/

</style>
