<template>
    <div class="">
        <swiper v-if="details.length!==0" class="swiper c-swiper pb-6 pb-lg-0" :class="{'slides-j-c-md': details.length<3}" :options="swiperOption">
            <swiper-slide class="" v-for="(item,i) in details" :key="i">
                <training-card-slider-item :data="item"></training-card-slider-item>
            </swiper-slide>
            <div v-if="details.length > 1" class="swiper-pagination" slot="pagination"></div>
            <div v-if="details.length > 1" class="swiper-button-prev mt-md-n7 mt-lg-0 swiper-button-white" slot="button-prev">
            </div>
            <div v-if="details.length > 1" class="swiper-button-next mt-md-n7 mt-lg-0" slot="button-next"></div>
            <!--            Navigation buttons for Medium Screens-->
            <!--            <div v-if="details.length > 2" class="swiper-pagination d-sm-none" slot="pagination"></div>-->
            <!--            <div v-if="details.length > 2" class="swiper-button-prev mt-md-n7 mt-lg-0 d-sm-none swiper-button-white" slot="button-prev">-->
            <!--            </div>-->
            <!--            <div v-if="details.length > 2" class="swiper-button-next mt-md-n7 mt-lg-0 d-sm-none" slot="button-next"></div>-->
        </swiper>
    </div>
</template>
<script> import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import TrainingCardSliderItem from '../../components/home-components/TrainingCardSliderItem';

export default {
    name: 'TrainingSwiperSmallScreens',
    title: 'Fade effect',
    components: { TrainingCardSliderItem, Swiper, SwiperSlide },
    props: {
        details: {
            type: Array, default: null
        }
    },
    data () {
        return {
            swiperOption: {
                autoHeight: true,
                slidesPerView: 1,
                spaceBetween: 0,
                pagination: { el: '.swiper-pagination', clickable: true },
                mousewheel: false,
                parallax: false,
                centeredSlides: false,
                // autoplay: true,
                autoplay: { delay: 6000, disableOnInteraction: false },
                navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
                breakpoints: {
                    1200: { speed: 1500 },
                    1024: { slidesPerView: 1, spaceBetween: 0, speed: 1000 },
                    768: { slidesPerView: 2, spaceBetween: 0, speed: 800 },
                    640: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    320: { slidesPerView: 1, spaceBetween: 0, speed: 800 }
                }
            }
        };
    },
    methods: {
        setBgImage (image, screen) {
            if (screen === 'web') {
                return { backgroundImage: 'url(' + image.webImg + ')' };
            } else if (screen === 'mobile') {
                return { backgroundImage: 'url(' + image.mobile + ')' };
            }
        }
    }
}
; </script>
<style lang="scss" scoped>
    .swiper {
        //height: 60vh;
        /*@media (min-width: 768px) and (max-width: 1024px) {*/
        /*    height: 58vh;*/
        /*}*/
        @media (min-width: 992px) {
            height: 89vh;
        }

        .swiper-slide {
            background-position: center;
            background-size: contain;
            padding-left: 1.5rem !important;
            padding-right: 1.5rem !important;
            @media (min-width: 992px) {
                background-size: cover !important;
            }

            &.slide-1 {
                //background-image: url('../../assets/img/banner/banner1.jpg')
            }

            &.slide-2 {
                //background-image: url('../../assets/img/banner/banner2.jpg')
            }

            &.slide-3 {
                //background-image: url('../../assets/img/banner/banner3.jpeg')
            }
        }
    }

    .swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
        //background-color: rgba(0, 0, 0, 0.4);
        //width: 4rem;
        bottom: 1.25rem !important;
        left: 0 !important;
        @media (min-width: 992px) {
            bottom: 2rem !important;
        }

        .swiper-pagination-bullet {
            border: 1px solid black !important;
            //background: white !important;
            left: 0 !important;
        }

        .swiper-pagination-bullet-active {
            background-color: gray !important;
        }

    }

    .wiper-pagination-bullet-active {
        background: gray !important;
    }

    .swiper-pagination {
        //width: 100%;
        bottom: -1rem;
    }

    .swiper-button-prev {
        left: 0.2rem;
        //padding-left: 3rem;
        z-index: 799 !important;

        &::after {
            content: '';
            width: 36px;
            height: 36px;
            //padding-left: 3rem;
            z-index: 799;
            background: url("../../assets/web/Icons/arrow-left.svg") !important;
            background-size: contain !important;
            background-repeat: no-repeat !important;
        }

        /*&.swiper-button-disabled {*/
        /*    display: none;*/
        /*}*/
    }

    .swiper-button-next {
        right: 0.2rem;

        &::after {
            content: '';
            width: 36px;
            height: 36px;
            background: url("../../assets/web/Icons/arrow-right.svg") !important;
            background-size: contain !important;
            background-repeat: no-repeat !important;
        }

        /*&.swiper-button-disabled {*/
        /*    display: none;*/
        /*}*/
    }
</style>
