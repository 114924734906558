<template>
    <div>
        <div class="container py-5">
            <div class="row">
                <div class="col-12">
                    <section-heading size="xlg" title="VIDEO GALLERY"
                                     info="Immortalize those magical moments of Cyberfort events & our Teamwork."></section-heading>
                </div>
                <template v-if="details === null || details.length === 0">
                    <p class="text-center w-100p py-7">
                        Not found any videos to play..!
                    </p>
                </template>
                <div class="col-12">
                    <CoolLightBox
                        :items="videos"
                        :index="index"
                        @close="index = null">
                    </CoolLightBox>
                </div>

                <template v-for="(video, videoIndex) in details">
                    <transition v-if="videoIndex < 4 || viewMore === true" name="zoom" mode="in-out"
                                v-bind:key="videoIndex">
                        <div class="col-md-6" :class="{'mt-4 mt-lg-0': videoIndex !==0 }"
                             @click="openVideo(video.video_link)">
                            <div class="img-contain cursor-pointer" :style="setBgImage(video.thumbnail)"></div>
                            <h4 class="text-primary mt-2 fs-lg-1 font-manrope-extra-bold">{{ video.title }}</h4>
                        </div>
                    </transition>
                </template>
                <div class="col-12 mt-5 text-center" v-if="details.length!==0">
                    <btn v-if="!viewMore && details.length > 4" @click="viewMore=true" text="View All" size="lg" border-radius="0"
                         class="px-5 fs-lg-1 font-poppins-regular"></btn>
                    <btn v-if="viewMore" @click="viewMore=false" text="View Less" size="lg" border-radius="0"
                         class="px-5 fs-lg-1 font-poppins-regular"></btn>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import axios from 'secure-axios';
import urls from '@/data/urls';

export default {
    name: 'VideoGalleryPage',
    data () {
        return {
            index: null,
            viewMore: false,
            dataLoading: false,
            details: null
        };
    },
    mounted () {
        this.loadData();
    },
    methods: {
        openVideo (url) {
            window.open(url);
        },
        setBgImage (image) {
            return { backgroundImage: 'url(' + image + ')' };
        },
        async loadData () {
            this.dataLoading = true;
            const response = await axios.get(urls.gallery.videoGallery);
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type: 'danger'
                });
            }
        }
    }
};
</script>

<style scoped lang="scss">
.img-contain {
    position: relative;
    height: 22rem !important;
    width: auto;
    background-position: center;
    border-radius: var(--round-2);
    background-repeat: no-repeat;
    background-size: cover;
    @media (min-width: 768px) and (max-width: 1024px) {
        height: 12rem !important;
    }
}
</style>
