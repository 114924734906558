<template>
    <div>
        <div class="swiper-button-next cursor-pointer" slot="button-next" v-if="details.length >3"></div>
        <swiper class="swiper px-lg-71 pt-lg-5 pb-lg-6" :class="{'h-1-item': details.length === 1, 'h-2-item':details.length === 2}"
                ref="swiper" :options="swiperOption">
            <swiper-slide v-for="(item,i) in details" :key="i">
                <training-card-slider-item class="mt-2" :data="item"></training-card-slider-item>
            </swiper-slide>
        </swiper>
        <div class="swiper-button-prev cursor-pointer" slot="button-prev" v-if="details.length >3"></div>
    </div>

</template>

<script>
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
import TrainingCardSliderItem from '../../components/home-components/TrainingCardSliderItem';

export default {
    name: 'TrainingSwiperVertical',
    title: 'Change direction',
    components: {
        TrainingCardSliderItem,
        Swiper,
        SwiperSlide
    },
    props: {
        details: {
            type: Array, default: null
        }
    },
    data () {
        return {
            swiperOption: {
                slidesPerView: 3,
                spaceBetween: 0,
                autoHeight: false,
                direction: 'vertical',
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                },
                breakpoints: {
                    1200: { speed: 1500 },
                    1024: { slidesPerView: 3, spaceBetween: 0, speed: 1000 },
                    768: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    640: { slidesPerView: 1, spaceBetween: 0, speed: 800 },
                    320: { slidesPerView: 1, spaceBetween: 0, speed: 800 }
                }
                // on: {
                //     resize: () => {
                //         this.$refs.swiper.$swiper.changeDirection(
                //             window.innerWidth <= 960
                //                 ? 'vertical'
                //                 : 'horizontal'
                //         );
                //     }
                // }
            }
        };
    }
};
</script>

<style lang="scss" scoped>
//@import './base.scss';

//@media (max-width: $container-width) {
//  .swiper-button-next {
//    right: 20px;
//    transform: rotate(90deg);
//  }
//
//  .swiper-button-prev {
//    left: 20px;
//    transform: rotate(90deg);
//  }
//}

.swiper {
    //height: 100vh;
    @media (min-width: 992px) {
        //height: 104vh;
        height: 101.5vh;
        height: 120vh;
        padding-top: 0.2rem;
        padding-bottom: 0.4rem;
    }
     @media (min-width: 1400px) {
         //height: 101.5vh;
         height: 103.5vh;
     }

    .swiper-slide {
        height: 17rem !important;
        @media (min-width: 992px) {
            //height: 17rem !important;
            //height: 33% !important;
            height: 15rem !important;
            //margin-top:0.5rem;
        }
    }

    &.h-1-item {
        @media (min-width: 992px) {
            /*height: 74vh;*/
            height: 49vh;
        }
        @media (min-width: 1800px) {
            height: 40vh;
        }
    }

    &.h-2-item {
        @media (min-width: 992px) {
            height: 70vh;
        }
        @media (min-width: 1800px) {
            height: 40vh;
        }
    }
}

.swiper-button-prev {
    position: relative;
    left: 50%;
    transform: translateX(-50%);

    &::after {
        content: '';
        width: 36px;
        height: 36px;
        background: url("../../assets/web/home/Vector1.svg") !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
    }
}
.swiper-button-prev {
    position: relative;
    left: 50%;
    top: 28px;
    transform: translateX(-50%);
}
.swiper-button-next {
    left: 50%;
    transform: translateX(-50%);
    bottom: 0rem;
    position: relative;

    &::after {
        content: '';
        width: 36px;
        height: 36px;
        background: url("../../assets/web/home/Vector2.svg") !important;
        background-size: contain !important;
        background-repeat: no-repeat !important;
    }
}
.px-lg-71{
    @media (min-width: 992px) {
        //padding-left: 7.5rem !important;
        padding-left: 8.2rem !important;
    }
    @media (min-width: 992px) {
        //padding-right: 7.5rem !important;
        padding-right: 8.2rem !important;
    }
}
</style>
