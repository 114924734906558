<template>
    <div>
        <!--    responsive-->
        <div class="d-lg-none ">
            <div class="container  w-100p">
                <div>
                    <event-swiper :schedule="training.schedule"></event-swiper>
                </div>
                <div class="fl-x-cc  ">
                    <btn v-if="training.future_training" @click="$refs.trainingRegister.showPopUpModal()"
                         border-radius="0" color="danger" class="font-manrope-semi-bold px-5 fs-1 mt-3">Register
                    </btn>
                    <btn v-else border-radius="0" color="danger"
                         class="font-manrope-semi-bold px-5 fs-1 mt-3">Registration Closed
                    </btn>

                    <training-modal ref="trainingRegister" :training_data="training"></training-modal>
                </div>

            </div>
        </div>
        <!--        web-->
        <div class="d-sm-none d-md-none">
            <div class="container  w-45r">
                <div>
                    <event-swiper :schedule="training.schedule"></event-swiper>
                </div>
                <div class="fl-x-cc  ">
                    <btn v-if="training.future_training" @click="$refs.trainingRegisterWeb.showPopUpModal()"
                         border-radius="0" color="danger" class="font-manrope-semi-bold px-5 fs-1 mt-3">Register
                    </btn>
                    <btn v-else border-radius="0" color="danger" :disabled="true"
                         class="font-manrope-semi-bold px-5 fs-1 mt-3">Registration Closed
                    </btn>

                    <training-modal ref="trainingRegisterWeb" :training_data="training"></training-modal>
                </div>

            </div>

        </div>
    </div>
</template>

<script>
// import InnerPageCard from '@components/ui/InnerPageCard';
import TrainingModal from '../../components/Modals/TrainingModal';
import EventSwiper from '../../components/Training/EventsSwiper';

export default {
    name: 'SchedulePage',
    props: {
        training: {
            type: Object, default: null
        }
    },
    components: { EventSwiper, TrainingModal }
};
</script>

<style scoped>

</style>
