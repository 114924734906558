<template>
    <div>
        <div class="container row">
            <div class="col-lg-6 pl-lg-6 pl-4 mt-lg-n5 mt-n4">
                <img src="../../assets/img/Images/business-people-meeting.jpg">
            </div>
<!--            <div class="col-lg-6 mt-2 pl-lg-5 pl-5 mt-lg-n5 ml-2">-->
<!--                <ul class="font-manrope-medium pb-5 bb-black">-->
<!--                    <li>Digital Transformation - security & controls design considerations & implementation assistance-->
<!--                    </li>-->
<!--                    <li>Assit in Data Protection impact Assessment(DPIA) and EU GDPR implementation</li>-->
<!--                    <li>Blockchain initiative - Security & controls assurance service</li>-->
<!--                    <li>Assist in 3rd party Cyber Security products & services selection & implementation</li>-->
<!--                </ul>-->
<!--            </div>-->
        </div>
    </div>
</template>

<script>
export default {
    name: 'SolutionsImage'
};
</script>

<style scoped>
img {
    width: 95%;
}

li {
    line-height: 2rem;
}

@media (max-width: 765px) {
    img {
        width: 107%;
    }
}

@media (min-width: 768px) and (max-width: 1024px) {
    img {
        width: 105%;
    }
}
</style>
